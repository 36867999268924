import useSlideOver from "../../../useSlideOver";
import SlideOverHeader from "../../../component/header";
import React, {useEffect, useState} from "react";
import SlideOverBody from "../../../component/body";
import UseContext from "../../../../../hooks/useContext";
import {dateToEpoch} from "../../../../../helper/helper";
import ScheduleWeekList from "../../../../schedule/ScheduleWeekList";
import SlideOverFooter from "../../../component/footer";
import Button from "../../../../button";
import ApiDatabase from "server";
import {toast} from "react-toastify";

const SlideOverSchedule = () => {
    const {removeAllParameters} = UseContext();
    const {toggleSlideOver} = useSlideOver();
    const token = localStorage.getItem('xsrfToken');
    const [dateStartMission, setDateStartMission] = useState(null)
    const [dateEndMission, setDateEndMission] = useState(null)
    const {updSchedule, setUpdSchedule, scheduleWeeks, setScheduleWeeks, missionDetails, scheduleWeeksNew, setScheduleWeeksNew} = UseContext()
    const [scheduleWeekDisplay, setScheduleWeekDisplay] = useState(0)
    const [error, setError] = useState(false)

    //Display planning
    useEffect(() => {
        if (dateStartMission && dateEndMission) {
            const dateStartSchedule = dateStartMission
            const dateEndSchedule = dateEndMission

            //return days between two given in parameter
            const weeksNew = timeSheets(dateStartSchedule, dateEndSchedule)
            const daysArrayNew = []
            for (let w in weeksNew) {
                const daysNew = [];
                for (let i = 0; i < weeksNew[w].length; i++) {
                    daysNew.push({
                        date: weeksNew[w][i],
                        week: w
                    })
                }
                daysArrayNew.push(daysNew)
            }
            let daysArrayNew2 = []
            let daysArrayCanBe2 = []
            for (let i = 0; i < daysArrayNew.length; i++) {
                if (!daysArrayCanBe2.includes(i)) {
                    let isDaysArrayCanBe2 = false;
                    if (daysArrayNew[i + 1]) {
                        if (daysArrayNew[i].length + daysArrayNew[i + 1].length <= 7) {
                            isDaysArrayCanBe2 = true;
                            daysArrayCanBe2.push(i, i + 1)
                        }
                    }
                    if (isDaysArrayCanBe2) {
                        // add daysArrayNew[i] and daysArrayNew[i + 1] in daysArrayNew2
                        daysArrayNew2.push({
                            name: `Semaine ${('0' + (i + 1)).slice(-2)} & ${('0' + (i + 2)).slice(-2)}`,
                            days: [...daysArrayNew[i], ...daysArrayNew[i + 1]]
                        })
                    } else {
                        daysArrayNew2.push({name: `Semaine ${('0' + (i + 1)).slice(-2)}`, days: daysArrayNew[i]})
                    }
                }
            }

            if (scheduleWeeks.length !== 0) {
                for (let i = 0; i < daysArrayNew2.length; i++) {
                    for (let j = 0; j < daysArrayNew2[i].days.length; j++) {
                        for (let k = 0; k < scheduleWeeks.length; k++) {
                            for (let l = 0; l < scheduleWeeks[k].days.length; l++) {
                                if(dateToEpoch(daysArrayNew2[i].days[j].date).getTime() === dateToEpoch(scheduleWeeks[k].days[l].date).getTime()){
                                    daysArrayNew2[i].days[j].periods = scheduleWeeks[k].days[l].periods
                                }
                            }
                        }
                    }
                }
            }
            setScheduleWeeksNew(daysArrayNew2);
        }
    }, [dateStartMission, dateEndMission])

    useEffect(() => {
        if(scheduleWeeks.length > 0){
            for (let i = 0; i < scheduleWeeks.length; i++) {
                for (let j = 0; j < scheduleWeeks[i].days.length; j++) {
                    scheduleWeeks[i].days[j].date = dateToEpoch(new Date(scheduleWeeks[i].days[j].date))
                }
            }
            setScheduleWeeksNew(scheduleWeeks)
        }
    }, [])

    const timeSheets = (start, end)=>{
        const weeks = [];

        let startDate = dateToEpoch(new Date(start));
        let endDate = dateToEpoch(new Date(end));
        let week = [];
        while (startDate <= endDate) {

            week.push(dateToEpoch(startDate));

            if(startDate >= endDate || startDate.getDay() === 0){
                weeks.push(week);
                week = [];
            }
            startDate.setDate(startDate.getDate() + 1);
        }

        return weeks;
    }

    useEffect(() => {
        if(missionDetails?.duration?.datestart && missionDetails?.duration?.dateend){
            setDateStartMission(dateToEpoch(new Date(missionDetails?.duration?.datestart)))
            setDateEndMission(dateToEpoch(new Date(missionDetails?.duration?.dateend)))
        }
    }, [missionDetails]);

    const submitSchedule = () => {
        for (let i = 0; i < scheduleWeeksNew.length; i++) {
            for (let j = 0; j < scheduleWeeksNew[i].days.length; j++) {
                const dateSchedule = dateToEpoch(scheduleWeeksNew[i].days[j].date)
                const offsetMinutes = dateSchedule.getTimezoneOffset();
                const localDate = new Date(dateSchedule.getTime() - (offsetMinutes * 60 * 1000));
                scheduleWeeksNew[i].days[j].date = localDate.toISOString()
            }
        }

        ApiDatabase.updScheduleByIdMission({token, idMission: missionDetails._id, schedule: scheduleWeeksNew}, (res) => {
            setUpdSchedule(true)
            closeSlideOver()
            toast.success('Le planning a bien été modifié')
        }, (err) => {
            toast.error('Une erreur est survenue lors de la modification du planning')
        })
    }

    const closeSlideOver = () => {
        toggleSlideOver()
        removeAllParameters()
    }

    return (
        <>
            <SlideOverHeader hide={closeSlideOver} hideShow={true}>
                <div className="flex flex-col">
                    <h1 className="text-2xl font-bold">Planning de la mission</h1>
                    <div className={'text-sm text-gray-500'}>
                        {missionDetails.title} n°{missionDetails.id_mission}・{missionDetails.name_commercial}
                    </div>
                </div>
            </SlideOverHeader>
            <SlideOverBody>
                <div className={'flex flex-col mx-auto gap-6'}>
                    <div className={''}>
                        {
                            scheduleWeeksNew?.map((scheduleWeek, index) => {
                                return (
                                    <ScheduleWeekList key={index}
                                                      indexScheduleWeek={index}
                                                      scheduleWeek={scheduleWeek}
                                                      isPrevious={!!scheduleWeeksNew[index - 1]}
                                                      isNext={!!scheduleWeeksNew[index + 1]}
                                                      isHidden={scheduleWeekDisplay !== index}
                                                      setScheduleWeekDisplay={setScheduleWeekDisplay}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
            </SlideOverBody>
            <SlideOverFooter>
                <Button size={'LG'} color={'SECONDARY'} onClick={closeSlideOver}>Annuler</Button>
                <Button size={'LG'} color={'PRIMARY'} onClick={submitSchedule}>Modifier</Button>
            </SlideOverFooter>
        </>
    )
}

export default SlideOverSchedule