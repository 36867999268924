import ExperienceItem from "./components/experienceItem";
import {useState} from "react";
import {ChevronDown, ChevronTop} from "../../assets/icons";

const Experiences = ({ experiences }) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            {experiences.slice(0, open ? experiences.length : 3).map((experience, index) => (
                <ExperienceItem key={index} experience={experience} index={index} />
            ))}
            {experiences.length > 3 && (
                <div className={'text-yellow-500 underline cursor-pointer'} onClick={() => setOpen(!open)}>
                    <div className={'flex gap-1 items-center'}>
                        <div>
                            {open ? 'Voir moins' : 'Voir plus'}
                        </div>
                        <div>
                            {open ? <ChevronTop w={18} h={8} color={'#FF9600'}/> : <ChevronDown w={18} h={8} color={'#FF9600'}/>}
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default Experiences
