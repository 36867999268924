import React, {useEffect, useState} from 'react'
import CardVivier from './card.vivier.js'
import {NavLink} from 'react-router-dom'
import axios from "axios"
import UseContext from 'hooks/useContext.js'
import {ArrowTopLeft, GroupPeople, PositiveThumb} from "../../../../../assets/icons";
import ApiDatabase from "../../../../../server";

const HeaderVivier = () => {
    const {setListVivier, refreshUntreated, vivierAllHeaderVivier, setVivierAllHeaderVivier, nbAllViviersHeaderVivier, setNbAllViviersHeaderVivier} = UseContext()
    const token = localStorage.getItem('xsrfToken')
    const [nbRatingUntreatedTempWorkers, setNbRatingUntreatedTempWorkers] = useState(0);
    const [isWaitingUntreatedTempWorkers, setisWaitingUntreatedTempWorkers] = useState(false);
    const [isWaitingAllViviers, setisWaitingAllViviers] = useState(false);

    useEffect(() => {
        setisWaitingAllViviers(true)
        ApiDatabase.getVivierAll({},
            (data) => {
                setVivierAllHeaderVivier(data)
                setNbAllViviersHeaderVivier(data.length)
                setListVivier(vivierAllHeaderVivier)
                setisWaitingAllViviers(false)
            },
            (err) => {
                if (err.name !== "CanceledError") {
                    console.log(err)
                    setisWaitingAllViviers(false)
                }
            })
    }, [])


    useEffect(() => {
        setisWaitingUntreatedTempWorkers(true)
        ApiDatabase.getNbRatingUntreatedTempWorkers({token}, (data) => {
            setNbRatingUntreatedTempWorkers(data.nbReviews)
            setisWaitingUntreatedTempWorkers(false)
        }, (err) => {
            if (err.name !== "CanceledError") {
                console.log(err)
                setisWaitingUntreatedTempWorkers(false)
            }
        })
    }, [refreshUntreated]);


    return (
        <div className="flex gap-8">
            <div className={'my-auto flex gap-8'}>
                <NavLink to={'/listAllVivier'} className={'p-3 border rounded border-gray-100 h-20 w-64 flex gap-3'}>
                    <div className={''}>
                        <div className={'my-auto w-fit rounded-full p-2 border border-gray-100'}>
                            <GroupPeople wh={20} color={'#374151'}/>
                        </div>
                    </div>
                    <div className={'flex flex-col justify-between'}>
                        <div className={'w-full truncate text-lg text-gray-900'}>
                            Tous les viviers
                        </div>
                        <div className={'text-sm text-gray-700'}>
                            {isWaitingAllViviers ? (
                                <div className="w-4 h-4 bg-gray-200 rounded"></div>
                            ) : (
                                nbAllViviersHeaderVivier
                            )}
                        </div>
                    </div>
                    <div className={'flex flex-col w-full'}>
                        <div className={'mt-auto ml-auto'}>
                            <div className={'rounded-full bg-gray-100'}>
                                <ArrowTopLeft wh={20} color={'#6B7280'}/>
                            </div>
                        </div>
                    </div>
                </NavLink>
                <NavLink to={'/vivier-rating-temp-worker/rating-untreated'}
                         className={'p-3 border rounded border-gray-100 h-20 w-64 flex gap-3'}>
                    <div className={''}>
                        <div className={'my-auto w-fit rounded-full p-2 border border-gray-100'}>
                            <PositiveThumb wh={20} color={'#374151'}/>
                        </div>
                    </div>
                    <div className={'flex flex-col justify-between'}>
                        <div className={'w-full truncate text-lg text-gray-900'}>
                            Nouveaux avis
                        </div>
                        <div className={'text-sm text-gray-700'}>
                            {isWaitingUntreatedTempWorkers ? (
                                <div className="w-4 h-4 bg-gray-200 rounded"></div>
                            ) : (
                                nbRatingUntreatedTempWorkers
                            )}
                        </div>
                    </div>
                    <div className={'flex flex-col w-full'}>
                        <div className={'mt-auto ml-auto'}>
                            <div className={'rounded-full bg-gray-100'}>
                                <ArrowTopLeft wh={20} color={'#6B7280'}/>
                            </div>
                        </div>
                    </div>
                </NavLink>
                {/*divider vertically*/}
                <div className={'w-0.5 bg-gray-100 h-20 my-auto'}></div>
            </div>
            <div className={'my-auto flex overflow-y-auto gap-8'}>
                {vivierAllHeaderVivier && vivierAllHeaderVivier.map((vivier, key) => {
                    if(vivier._id.toString() === '64e8cfd10b4f73f442432c9f') return null
                    else return (
                        <CardVivier key={key} vivier={vivier}/>
                    );
                })}
            </div>
        </div>
    )
}

export default HeaderVivier