import Button from "../../../../button";
import React, {useEffect, useState} from "react";
import SlideOverHeader from "../../../component/header";
import SlideOverBody from "../../../component/body";
import SlideOverFooter from "../../../component/footer";
import useSlideOver from "../../../useSlideOver";
import ApiDatabase from "server";
import {toast} from "react-toastify";
import RichTextEditor from "../../../../RichTextEditor/RichTextEditor";
import UseContext from "../../../../../hooks/useContext";
import {BanIcon, CheckIcon} from "../../../../../assets/icons";

const SlideOverQualification = () => {
    const {toggleSlideOver} = useSlideOver();
    const {refreshQualifications, setRefreshQualifications, refreshQualification, setRefreshQualification, objID, setObjID} = UseContext()
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [initialDescription, setInitialDescription] = useState('')
    const [pcs, setPcs] = useState('')
    const [rome, setRome] = useState('')
    const [type, setType] = useState('')
    const [isToDisplayInMissionPreferences, setIsToDisplayInMissionPreferences] = useState(false)
    const [isActive, setIsActive] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const token = localStorage.getItem('xsrfToken')

    const handleSubmit = () => {
        if(name && description && pcs && type) {
            setIsLoading(true)
            if(objID) {
                ApiDatabase.updateQualification({token, idQualification: objID, name, description, pcs, rome, type, isToDisplayInMissionPreferences}, (data) => {
                    toast.success('La qualification a bien été modifiée.')
                    setIsLoading(false)
                    setRefreshQualification(data)
                    quitSlideOver()
                }, (err) => {
                    if (err.name !== "CanceledError") {
                        console.log(err)
                        toast.error(err.response.data.message)
                        setIsLoading(false)
                    }
                })
            } else {
                ApiDatabase.postQualification({token, name, description, pcs, rome, type, isToDisplayInMissionPreferences}, (data) => {
                    toast.success('La qualification a bien été créée.')
                    setIsLoading(false)
                    setRefreshQualifications(!refreshQualifications)
                    quitSlideOver()
                }, (err) => {
                    if (err.name !== "CanceledError") {
                        console.log(err)
                        toast.error(err.response.data.message)
                        setIsLoading(false)
                    }
                })
            }
        } else {
            toast.error('Veuillez remplir tous les champs.')
        }
    }

    const onEditorStateChange = (editorState) => {
        setDescription(editorState)
    }

    useEffect(() => {
        if(objID) {
            ApiDatabase.getQualificationByID({token, idQualification: objID}, (data) => {
                setName(data.name)
                setDescription(data.desc)
                setInitialDescription(data.desc)
                setPcs(data.codePCS)
                setRome(data.codeROME)
                setType(data.type)
                setIsToDisplayInMissionPreferences(data.displayInMissionPreferences)
                setIsActive(data.isActive)
            }, (err) => {
                if (err.name !== "CanceledError") {
                    console.log(err)
                    toast.error(err.response.data.message)
                }
            })
        }
    }, [objID]);

    const quitSlideOver = () => {
        toggleSlideOver()
        setObjID('')
    }

    const delQualification = () => {
        if(objID) {
            ApiDatabase.delQualification({token, idQualification: objID}, (data) => {
                toast.success('La qualification a bien été désactivée.')
                setRefreshQualification(data)
                quitSlideOver()
            }, (err) => {
                if (err.name !== "CanceledError") {
                    console.log(err)
                    toast.error(err.response.data.message)
                }
            })
        }
    }

    const activeQualification = () => {
        if(objID) {
            ApiDatabase.activateQualification({token, idQualification: objID}, (data) => {
                toast.success('La qualification a bien été activée.')
                setRefreshQualification(data)
                quitSlideOver()
            }, (err) => {
                if (err.name !== "CanceledError") {
                    console.log(err)
                    toast.error(err.response.data.message)
                }
            })
        }
    }

    return (
        <>
            <SlideOverHeader hide={quitSlideOver} hideShow={true}>
                {objID ? 'Modifier une qualification' : 'Créer une qualification'}
                <div className={'text-gray-500 text-base'}>
                    Un vivier spécifique au poste sera disponible dans 'Viviers', et une nouvelle option de qualification sera ajoutée à la création de missions.
                </div>
            </SlideOverHeader>
            <SlideOverBody>
                <div className={'flex flex-col gap-4'}>
                    <div>
                        <label className='ft-sm text-gray-500'>
                            <div>
                                Nom du poste
                            </div>
                            <div>
                                <input type="text"
                                       className='w-full h-10 pl-3 pr-8 ft-b border rounded-lg text-gray-900'
                                       value={name}
                                       onChange={e => setName(e.target.value)}
                                />
                            </div>
                        </label>
                    </div>
                    <div className="flex flex-col">
                        <label>
                            <div className={'ft-sm text-gray-500'}>
                                Description
                            </div>
                            <RichTextEditor initialContent={initialDescription} onEditorStateChange={onEditorStateChange}/>
                        </label>
                    </div>
                    <div className={'flex gap-3'}>
                        <div className={'my-auto w-full'}>
                            <label className='ft-sm text-gray-500'>
                                <div>
                                    PCS
                                </div>
                                <div>
                                    <input type="text"
                                           className='w-full h-10 pl-3 pr-8 ft-b border rounded-lg text-gray-900'
                                           value={pcs}
                                           onChange={e => setPcs(e.target.value)}
                                    />
                                </div>
                            </label>
                        </div>
                        <div className={'my-auto w-full'}>
                            <label className='ft-sm text-gray-500'>
                                <div>
                                    ROME <span className={'ft-sm text-gray-400'}>- (Optionnel)</span>
                                </div>
                                <div>
                                    <input type="text"
                                           className='w-full h-10 pl-3 pr-8 ft-b border rounded-lg text-gray-900'
                                           value={rome}
                                           onChange={e => setRome(e.target.value)}
                                           onInput={e => {
                                               let value = e.target.value
                                               if (value) {
                                                   value = Math.max(0, parseInt(value)).toString().slice(0, 4)
                                                   //transform lower case to upper case
                                                   value= value.toUpperCase()
                                                   //accept only letters and numbers
                                                   value = value.replace(/[^A-Z0-9]/g, '')
                                               }
                                               setRome(value)
                                           }}
                                    />
                                </div>
                            </label>
                        </div>
                    </div>
                    <div>
                        <label className='ft-sm text-gray-500'>
                            <div>
                                Secteur
                            </div>
                            <select className="w-full h-10 pl-2 pr-3 ft-b placeholder-gray-600 border rounded-lg"
                                    onChange={(e) => setType(e.target.value)}
                                    value={type}
                            >
                                <option value="">Choisir un secteur</option>
                                <option value="sale">Vente</option>
                                <option value="logistic">Logistique</option>
                                <option value="food">Restauration</option>
                                <option value="largeDistribution">Grande distribution</option>
                                <option value="administration">Administration</option>
                                <option value="industry">Industrie</option>
                                <option value="other">Autre</option>
                            </select>
                        </label>
                    </div>
                    <div className={'border rounded-lg p-4 select-none'}>
                        <label className={`flex gap-2 cursor-pointer`}>
                            <input type="checkbox"
                                   className={'w-4 h-4 accent-yellow-400 m-1'}
                                   onChange={() => setIsToDisplayInMissionPreferences(!isToDisplayInMissionPreferences)}
                                   checked={isToDisplayInMissionPreferences}
                            />
                            <div className={''}>
                                <div className={''}>Afficher dans "Préférences de missions"</div>
                                <div className={'text-gray-500'}>L’intérimaire pourra placer se poste dans sa liste de souhait.</div>
                            </div>
                        </label>
                    </div>
                </div>
            </SlideOverBody>
            <SlideOverFooter>
                {isLoading ? (
                    <>
                        <div className={'flex justify-between w-full'}>
                            <div className={'flex justify-end gap-2 w-full'}>
                                <Button size={'LG'} color={'DISABLED'}>Annuler</Button>
                                <Button size={'LG'} color={'DISABLED'}>Confirmation ...</Button>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className={'flex justify-between w-full'}>
                            {(objID && isActive) && (
                                <div className={'flex'}>
                                    <Button size={'LG'} color={'DEFAULT'} onClick={() => delQualification()}>
                                        <div className={'flex gap-2'}>
                                            <div className={'my-auto'}>
                                                <BanIcon wh={20} color={'#111827'}/>
                                            </div>
                                            <div className={'my-auto'}>
                                                Désactiver
                                            </div>
                                        </div>
                                    </Button>
                                </div>
                            )}
                            {(objID && !isActive) && (
                                <div className={'flex'}>
                                    <Button size={'LG'} color={'DEFAULT'} onClick={() => activeQualification()}>
                                        <div className={'flex gap-2'}>
                                            <div className={'my-auto'}>
                                                <CheckIcon w={24} h={12} color={'#111827'}/>
                                            </div>
                                            <div className={'my-auto'}>
                                                Activer
                                            </div>
                                        </div>
                                    </Button>
                                </div>
                            )}
                            <div className={'flex justify-end gap-2 w-full'}>
                                <Button size={'LG'} color={'SECONDARY'} onClick={quitSlideOver}>Annuler</Button>
                                <Button size={'LG'} color={'PRIMARY'} onClick={handleSubmit}>Confirmer</Button>
                            </div>
                        </div>
                    </>
                )}
            </SlideOverFooter>
        </>
    )
}

export default SlideOverQualification