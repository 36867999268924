import { useState } from "react";
import TrainingItem from "./components/trainingItem";
import {ChevronDown, ChevronTop} from "../../assets/icons";

const Trainings = ({ formations }) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            {formations.slice(0, open ? formations.length : 3).map((formation, index) => (
                <TrainingItem key={index} formation={formation} index={index} />
            ))}
            {formations.length > 3 && (
                <div className={'text-yellow-500 cursor-pointer'} onClick={() => setOpen(!open)}>
                    <div className={'flex gap-1 items-center'}>
                        <div>
                            {open ? 'Voir moins' : 'Voir plus'}
                        </div>
                        <div>
                            {open ? <ChevronTop w={18} h={8} color={'#FF9600'}/> : <ChevronDown w={18} h={8} color={'#FF9600'}/>}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Trainings;
