import React from 'react';
import {Plus, Close} from "assets/icons";

const Chip = ({color, type, children, disabled, onClick, select,icon}) => {
    const CHIP_COLOR = {
        PRIMARY: 'bg-gray-100 ',
        WARNING: 'bg-yellow-100 text-yellow-800',
        ALERT: 'bg-red-100 text-red-800',
        INFO: 'bg-green-100 text-green-800',
    }
    const CHIP_TYPE = {
        SELECTOFF: 'rounded text-gray-800',
        SELECTON: 'rounded bg-gray-500 text-white',
        INDICATOR: 'rounded-xl'
    }
    const typeClassName = CHIP_TYPE[type || 'INDICATOR']
    const colorClassName = CHIP_COLOR[color || 'PRIMARY']

    return (
        <button
            className={'ft-sm h-6 w-auto px-2  flex items-center justify-center gap-1 ' + typeClassName + ' ' + colorClassName}
            disabled={disabled}
        >
            <span className='px-2 whitespace-nowrap'>
                {children}
            </span>
            {icon?
                select ?
                    <span onClick={onClick}>
                    <Close wh={15} color={'#ffffff'}/>
                    </span>:
                    <span onClick={onClick}>
                    <Plus wh={15} className='fill-gray-400'/>
                    </span>

            :''}
        </button>
    );
};

export default Chip;