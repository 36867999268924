import {dateWordShort} from "../../../helper/helper";
import {Pencil, Warning} from "../../../assets/icons";
import React from "react";
import UseContext from "../../../hooks/useContext";
import useSlideOver from "../../SlideOver/useSlideOver";
import Tooltip from "../../tooltips/Tooltips";

const TbodyQualification = ({ champsBody }) => {
    const {setObjID}=UseContext()
    const {toggleSlideOver} = useSlideOver();

    const editQualification = (idQualification) => {
        setObjID(idQualification)
        toggleSlideOver('qualification')
    }

    return (
        <>
            {champsBody?.map((item, index) =>
                <tr key={index} className={'border-b-2 border-gray-100'}>
                    <td className={'py-5 text-gray-900'}>
                        <div className={'flex gap-2'}>
                            <div className={'my-auto'}>{item.name}</div>
                            {item.isActive === false && (
                                <div className={'my-auto'}>
                                    <span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-insetbg-red-50 text-red-600 ring-red-500/10`}>
                                        Désactivé
                                    </span>
                                </div>
                            )}
                            {!item.idBestt &&
                                <div className={'my-auto'}>
                                    <Tooltip text={`La mission n'a pas été référencée sur BeSTT`} placement='right'>
                                        <div><Warning wh={17} color={"#EF4444"}/></div>
                                    </Tooltip>
                                </div>
                            }
                        </div>
                    </td>
                    <td className={'py-5 text-gray-900'}>
                        <span className={`inline-flex items-center rounded-md px-2 py-1 text-sm ring-1 ring-inset
                        ${item.type === 'sale' && 'bg-gray-50 text-gray-600 ring-gray-500/10'}
                        ${item.type === 'food' && 'bg-green-50 text-green-600 ring-green-500/10'}
                        ${item.type === 'logistic' && 'bg-yellow-50 text-yellow-600 ring-yellow-500/10'}
                        ${item.type === 'largeDistribution' && 'bg-blue-50 text-blue-600 ring-blue-500/10'}
                        ${item.type === 'administration' && 'bg-purple-50 text-purple-600 ring-purple-500/10'}
                        ${item.type === 'industry' && 'bg-orange-50 text-orange-600 ring-orange-500/10'}
                        ${item.type === 'other' && 'bg-red-50 text-red-600 ring-red-500/10'}
                        `}>
                            {item.type === 'sale' && 'Vente'}
                            {item.type === 'food' && 'Restauration'}
                            {item.type === 'logistic' && 'Logistique'}
                            {item.type === 'largeDistribution' && 'Grande distribution'}
                            {item.type === 'administration' && 'Administration'}
                            {item.type === 'industry' && 'Industrie'}
                            {item.type === 'other' && 'Autre'}
                        </span>
                    </td>
                    <td className={'py-5 text-gray-500'}>{item.createdAt ? dateWordShort(item.createdAt) : 'N/A'}</td>
                    <td className={'py-5 text-gray-500'}>
                        <div className={'cursor-pointer'} onClick={() => editQualification(item._id)}>
                            <Pencil wh={18} color={'#111827'}/>
                        </div>
                    </td>
                </tr>
            )
            }
        </>
    );
}

export default TbodyQualification;