import Modalheader from "../../../components/header";
import ModalBody from "../../../components/body";
import Button from "../../../../button";
import ModalFooter from "../../../components/footer";
import ApiDatabase from "../../../../../server";
import useModal from "../../../useModal";
import UseContext from "../../../../../hooks/useContext";

const ModalComment = () => {
    const {toggle} = useModal();
    const {commentID, setCommentIDUpd} = UseContext()
    const token = localStorage.getItem('xsrfToken')

    const handleSubmit = (e) => {
        ApiDatabase.deleteCommentTempWorker({token, idComment: commentID}, (data) => {
            setCommentIDUpd(commentID);
            toggle();
        }, (err) => {
            if (err.name !== "CanceledError") {
                console.log(err)
            }
        })
    }

    return (
        <>
            <Modalheader hide={toggle} hideShow={true}>
                Suppresssion d'un commentaire
            </Modalheader>
            <ModalBody>
                Êtes-vous sûr de vouloir supprimer ce commentaire ?
            </ModalBody>
            <ModalFooter>
                <Button size={'LG'} color={'SECONDARY'} onClick={toggle}>Fermer</Button>
                <Button size={'LG'} color={'PRIMARY'} onClick={handleSubmit}>Confirmer</Button>
            </ModalFooter>
        </>
    )
}

export default ModalComment;
