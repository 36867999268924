import useSlideOver from "../../../useSlideOver";
import UseContext from "../../../../../hooks/useContext";
import SlideOverHeader from "../../../component/header";
import SlideOverBody from "../../../component/body";
import SlideOverFooter from "../../../component/footer";
import ApiDatabase from "server";
import React, {useEffect, useState} from "react";
import {ChevronLeft, ChevronRight, Close, CrossIcon} from "../../../../../assets/icons";
import Table from "../../../../table/table";
import Banner from "../../../../banner/banner";
import Button from "../../../../button";
import {toast} from "react-toastify";
import TextSelectMultipleBigAccount from "../../../../textSelectMultiple/textSelectMultipleBigAccount";

const SlideOverCompany = () => {
    const {removeAllParameters} = UseContext()
    const {objID, coeffs, refreshCompanyBigAccount}=UseContext()
    const {toggleSlideOver} = useSlideOver();
    const [companyInfo, setCompanyInfo] = useState({})
    const token = localStorage.getItem('xsrfToken')
    const [isShowCoeff, setIsShowCoeff] = useState(false)
    const [isCoeffCompleted, setIsCoeffCompleted] = useState(false)
    const [isCoeffSend, setIsCoeffSend] = useState(false)
    const [isCostEstimateDisplay, setIsCostEstimateDisplay] = useState(false)
    const [bigAccount, setBigAccount] = useState('')
    const [isWaiting, setIsWaiting] = useState(false)

    const roleName = {
        200: 'Observateur',
        210: 'Responsable',
        215: 'Directeur',
        225: 'Admin'
    }

    const titlesCoeff = ['TRANCHES DE SALAIRE BRUT MENSUEL', 'COEFF. DÉLÉGATION', 'COEFF. GESTION']

    useEffect(() => {
        if(objID){
            setIsWaiting(true)
            ApiDatabase.getCompanyInfo({token: token, id: objID}, (data) => {
                setCompanyInfo(data[0])
                setIsWaiting(false)
            }, (err) => {
                if (err.name !== "CanceledError") {
                    console.error(err)
                }
            })
        }
    }, [objID])

    useEffect(() => {
        if(companyInfo?.coefficients){
            if(companyInfo?.coefficients?.length > 0){
                for (let i = 0; i < companyInfo?.coefficients.length; i++) {
                    if(companyInfo?.coefficients[i]?.management === null || companyInfo?.coefficients[i]?.delegation === null){
                        setIsCoeffCompleted(false)
                        break
                    } else {
                        setIsCoeffCompleted(true)
                        setIsCoeffSend(true)
                    }
                }
            } else {
                setIsCoeffCompleted(false)
            }
        } else {
            setIsCoeffCompleted(false)
        }
    }, [companyInfo])

    const submitCoeff = () => {
        if(coeffs?.length > 0){

            let isCoeffFormAdd = false
            for (let i = 0; i < coeffs.length; i++) {
                if(coeffs[i]?.management !== null || coeffs[i]?.delegation !== null){
                    isCoeffFormAdd = true
                }
            }

            if(isCoeffFormAdd){
                ApiDatabase.postCoefficient({token: token, idCompany: objID, coefficients: coeffs}, (data) => {
                    setIsCoeffSend(true)
                    toast.success('Les coefficients ont bien été ajoutés')
                }, (err) => {
                    if (err.name !== "CanceledError") {
                        console.error(err)
                    }
                })
            } else {
                toast.error('Aucun champ n\'est renseigné')
            }
        } else {
            toast.error('Aucun champ n\'est renseigné')
        }
    }

    const manageDisplayCostEstimate = () => {
        ApiDatabase.putDisplayCostEstimate({token: token, idCompany: objID, displayCostEstimate: !isCostEstimateDisplay}, (data) => {
            setIsCostEstimateDisplay(!isCostEstimateDisplay)
            toast.success('Les estimations de coût sont maintenant visible par le client')
        }, (err) => {
            if (err.name !== "CanceledError") {
                console.error(err)
            }
        })
    }

    useEffect(() => {
        ApiDatabase.getDisplayCostEstimate({token: token, idCompany: objID}, (data) => {
            setIsCostEstimateDisplay(data?.displayCostEstimate)
        }, err => {
            if (err.name !== "CanceledError") {
                console.error(err)
            }
        })
    }, [])

    useEffect(() => {
        ApiDatabase.getCompanyBigAccount({token: token, idCompany: objID}, (data) => {
            setBigAccount(data?.bigAccount ?? '')
        }, err => {
            if (err.name !== "CanceledError") {
                console.error(err)
            }
        })
    }, [refreshCompanyBigAccount]);

    const deleteCompanyBigAccount = () => {
        ApiDatabase.deleteCompanyBigAccount({token: token, idCompany: objID}, (data) => {
            toast.success('Le grand compte a bien été supprimé')
            setBigAccount('')
        }, error => {
            toast.error(`Une erreur est survenue lors de la suppression du grand compte ${error.response.data.message}.`)
        })
    }

    const closeSlideOver = () => {
        toggleSlideOver()
        removeAllParameters()
    }

    return (
        <>
            {isWaiting ? (
                <>
                    <SlideOverHeader hide={closeSlideOver} hideShow={true}>
                        <div className={'animate-pulse'}>
                            <div className="w-56 h-5 bg-gray-200 rounded"></div>
                        </div>
                    </SlideOverHeader>
                    <SlideOverBody>
                        <div className={'flex flex-col gap-2 animate-pulse'}>
                            <button type={'button'} className={'text-left border rounded p-3 mb-5'}>
                                <div className={'flex justify-between'}>
                                    <div className={'my-auto'}>
                                        <div className="w-56 h-3 bg-gray-200 rounded"></div>
                                    </div>
                                    <div className={'my-auto'}>
                                        <ChevronRight wh={24} color={'#111827'}/>
                                    </div>
                                </div>
                            </button>
                            <div className={''}>
                                <div className={'border rounded p-3 flex justify-between gap-2'}>
                                    <div className={'my-auto w-full'}>
                                        <div className="w-56 h-3 bg-gray-200 rounded"></div>
                                    </div>
                                    <div className={'my-auto w-full'}>
                                        <div className="w-56 h-6 bg-gray-200 rounded"></div>
                                    </div>
                                </div>
                            </div>
                            <div className={''}>
                                <div className={'border rounded p-3 flex justify-between gap-2'}>
                                    <div className={'my-auto'}>
                                        <div className="w-72 h-3 bg-gray-200 rounded"></div>
                                    </div>
                                </div>
                            </div>
                            <div className={'border rounded p-3 flex flex-col gap-1'}>
                                <div className="w-56 h-3 bg-gray-200 rounded"></div>
                                <div className="w-56 h-3 bg-gray-200 rounded"></div>
                                <div className="w-36 h-3 bg-gray-200 rounded"></div>
                            </div>
                        </div>
                    </SlideOverBody>
                </>
            ) : (
                <>
                    {
                        !isShowCoeff ? (
                            <>
                                <SlideOverHeader hide={closeSlideOver} hideShow={true}>
                                    Détail de {companyInfo?.name_commercial}
                                </SlideOverHeader>
                                <SlideOverBody>
                                    <div className={'flex flex-col gap-2'}>
                                        <button type={'button'} className={'text-left border rounded p-3 mb-5'}
                                                onClick={() => setIsShowCoeff(true)}>
                                        <div className={'flex justify-between'}>
                                                <div className={'my-auto'}>
                                                    <div>Coefficient de facturation</div>
                                                    {
                                                        !isCoeffCompleted && (
                                                            <div className={'text-gray-500 flex gap-2'}>
                                                                <div className={'my-auto'}>
                                                                    <CrossIcon wh={20} color={'#EF4444'}/>
                                                                </div>
                                                                <div className={'my-auto'}>
                                                                    La page n'est pas complète
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                                <div className={'my-auto'}>
                                                    <ChevronRight wh={24} color={'#111827'}/>
                                                </div>
                                            </div>
                                        </button>
                                        <div className={''}>
                                            <div className={'border rounded p-3 flex justify-between gap-2'}>
                                                <div className={'my-auto w-full'}>
                                                    Associé au groupe :
                                                </div>
                                                <div className={'my-auto w-full'}>
                                                    {
                                                        bigAccount ? (
                                                            <div className={'p-3 border rounded'}>
                                                                <div className={'flex justify-between'}>
                                                                    <div>
                                                                        <div>{bigAccount?.name}</div>
                                                                        <div className={'text-gray-500 text-sm'}>
                                                                            le {bigAccount?.companies.find(item => item.id_company === objID)?.date && new Date(bigAccount?.companies.find(item => item.id_company === objID)?.date).toLocaleDateString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' })}
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div className={'cursor-pointer p-1 border rounded'} onClick={() => deleteCompanyBigAccount()}>
                                                                            <Close wh={18} color={'#374151'}/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : <TextSelectMultipleBigAccount/>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className={''}>
                                            <div className={'border rounded p-3 flex justify-between gap-2'}>
                                                <div className={'my-auto'}>
                                                    Affichage des estimations de coût du mois en cours
                                                </div>
                                                <div className={'my-auto'}>
                                                    <input type="checkbox" checked={isCostEstimateDisplay} onChange={() => manageDisplayCostEstimate()}/>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            companyInfo?.employees?.length > 0 && (
                                                companyInfo?.employees?.map((item, key) => (
                                                    <div key={key} className={'border rounded p-3'}>
                                                        <div>{roleName[item.role]}</div>
                                                        <div className={'text-gray-500'}>{item.firstname} {item.lastname}</div>
                                                        <div className={'text-gray-500 flex gap-1'}>
                                                            <a href={`mailto:${item.email}`} target={'_blank'}>{item.email}</a>
                                                            {
                                                                item.phone && (
                                                                    <>
                                                                        <div>·</div>
                                                                        <a href={`tel:${item.phone}`} target={'_blank'}>{item.phone}</a>
                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                ))
                                            )
                                        }
                                    </div>
                                </SlideOverBody>
                            </>
                        ) : (
                            <>
                                <SlideOverHeader hide={closeSlideOver} hideShow={true}>
                                    <div className={'text-sm mb-3'}>
                                        <button type={'button'} className={'border rounded p-2'} onClick={() => setIsShowCoeff(false)}>
                                            <div className={'flex justify-between gap-3'}>
                                                <div className={'my-auto'}>
                                                    <ChevronLeft wh={20} color={'#111827'}/>
                                                </div>
                                                <div className={'my-auto'}>
                                                    Profil de l'entreprise
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                    <div>Paramètres de paiement</div>
                                    <div className={'text-gray-500 text-sm'}>
                                        Consulter et gérer le coefficient de facuration et les conditions de paiement de l’entreprise utilisatrice.
                                    </div>
                                </SlideOverHeader>
                                <SlideOverBody>
                                    <div className={'flex flex-col gap-4'}>
                                        {
                                            isCoeffSend ? (
                                                <div className=''>
                                                    <Banner type='warning'>
                                                        Les données sont actuellement visible par l’entreprise.
                                                    </Banner>
                                                </div>
                                            ) : (
                                                <div className=''>
                                                    <Banner type='info'>
                                                        Les informations ne sont pas encore visible par le client, ajoutez des coefficients et confirmez afin de les rendre visible.
                                                    </Banner>
                                                </div>
                                            )
                                        }
                                        {
                                            companyInfo?.coefficients.length > 0 && (
                                                <Table champsBody={companyInfo?.coefficients} champsHeader={titlesCoeff} type={'coeff'}/>
                                            )
                                        }
                                    </div>
                                </SlideOverBody>
                                <SlideOverFooter>
                                    <Button size={'LG'} color={'SECONDARY'} onClick={closeSlideOver}>Annuler</Button>
                                    <Button size={'LG'} color={'PRIMARY'} onClick={submitCoeff}>Confirmer</Button>
                                </SlideOverFooter>
                            </>
                        )
                    }
                </>
            )}
        </>
    )
}

export default SlideOverCompany;