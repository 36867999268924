import React, { useState, useEffect } from 'react';
import { Outlet, useLocation } from "react-router-dom";
import PageForm from "../../components/pageForm/PageForm";
import TimeSheetSubNav from "../../components/subnav/timeSheet.subNav";
import UseContext from "hooks/useContext";
import ApiDatabase from "server";
import { dateToEpoch } from "../../helper/helper";
import Tooltip from "../../components/tooltips/Tooltips";
import { InformationCircle } from "../../assets/icons";
import DatePicker from "react-datepicker";
import removeAccents from "remove-accents";
import Fuse from "fuse.js";

const TimeSheet = () => {
    const location = useLocation();
    const {
        companyData,
        refreshTime,
        currentPageTimeSheetChecked, setCurrentPageTimeSheetChecked,
        timeSheetSearchTimeSheetChecked, setTimeSheetSearchTimeSheetChecked,
        timeSheetSearchTypeTimeSheetChecked, setTimeSheetSearchTypeTimeSheetChecked,
        dateRangeTimeSheetChecked, setDateRangeTimeSheetChecked,
        currentPageTimeSheetLitigation, setCurrentPageTimeSheetLitigation,
        timeSheetSearchTimeSheetLitigation, setTimeSheetSearchTimeSheetLitigation,
        timeSheetSearchTypeTimeSheetLitigation, setTimeSheetSearchTypeTimeSheetLitigation,
        dateRangeTimeSheetLitigation, setDateRangeTimeSheetLitigation,
        currentPageTimeSheetToBeChecked, setCurrentPageTimeSheetToBeChecked,
        timeSheetSearchTimeSheetToBeChecked, setTimeSheetSearchTimeSheetToBeChecked,
        timeSheetSearchTypeTimeSheetToBeChecked, setTimeSheetSearchTypeTimeSheetToBeChecked,
        dateRangeTimeSheetToBeChecked, setDateRangeTimeSheetToBeChecked
    } = UseContext();

    const [arrayTimeSheets, setArrayTimeSheets] = useState([]);
    const [isWaiting, setIsWaiting] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [limitElement, setLimitElement] = useState(20);
    const [totalPages, setTotalPages] = useState(0);
    const [countPerPage, setCountPerPage] = useState(0);
    const [countTotal, setCountTotal] = useState(0);
    const [timeSheetSearch, setTimeSheetSearch] = useState('');
    const [timeSheetSearchType, setTimeSheetSearchType] = useState('');
    const [dateRange, setDateRange] = useState([null, null]);
    const [isFirstSearchDone, setIsFirstSearchDone] = useState(false);
    const [currentPageBackup, setCurrentPageBackup] = useState(1);
    const [status, setStatus] = useState('1');
    const [timeSheetsToDisplay, setTimeSheetsToDisplay] = useState([]);

    const [startDate, endDate] = dateRange;

    const token = localStorage.getItem('xsrfToken');

    const manageTimeSheetSearch = () => {
        setIsWaiting(true);
        const timeSheetSearchOptions = {
            type: timeSheetSearchType !== '' ? timeSheetSearchType : null,
            startDate: startDate ? dateToEpoch(startDate) : null,
            endDate: endDate ? dateToEpoch(endDate) : null,
        };

        ApiDatabase.getTimeSheets({
                token, status, searchOptions: timeSheetSearchOptions
            },
            (data) => {
                setArrayTimeSheets(data.timeSheets);
                setCountPerPage(limitElement);
                setIsWaiting(false);
                setIsFirstSearchDone(true);
            },
            (err) => {
                if (err.name !== "CanceledError") {
                    console.log(err);
                }
            }
        );
    };

    useEffect(() => {
        setCurrentPage(1);
    }, [timeSheetSearch, timeSheetSearchType, startDate, endDate, status]);

    useEffect(() => {
        let filteredTimeSheets = arrayTimeSheets;
        if (timeSheetSearch.length > 0) {
            const searchTerms = timeSheetSearch
                .split(" ")
                .map((term) => removeAccents(term.toLowerCase()));
            const options = {
                keys: [
                    "id_time_sheet",
                    "id_user",
                    "title",
                    "id_mission_reel",
                    "lastname",
                    "firstname",
                    "id_bestt_temp_worker",
                    "reason_social",
                    "name_commercial",
                    "id_bestt_company",
                    "id_bestt_contract",
                    "id_mission",
                ],
                includeScore: true,
                threshold: 0.3,
                distance: 100,
                shouldSort: true,
                getFn: (obj, path) => {
                    const value = Fuse.config.getFn(obj, path);
                    if (value == null) {
                        return "";
                    }
                    return removeAccents(value.toString().toLowerCase());
                },
            };

            const fuse = new Fuse(arrayTimeSheets, options);
            const combinedResults = {};

            searchTerms.forEach((term) => {
                const result = fuse.search(term);
                result.forEach(({ item, score }) => {
                    const indexSearch = item._id + item.id_mission + term;
                    if (combinedResults[indexSearch]) {
                        combinedResults[indexSearch].score -= score;
                    } else {
                        combinedResults[indexSearch] = { item, score };
                    }
                });
            });

            const sortedResults = Object.values(combinedResults).sort(
                (a, b) => a.score - b.score,
            );
            filteredTimeSheets = sortedResults.map((result) => result.item);
        }

        setTimeSheetsToDisplay(
            filteredTimeSheets.slice(
                (currentPage - 1) * countPerPage,
                currentPage * countPerPage,
            ),
        );
        setCountTotal(filteredTimeSheets.length);
        setTotalPages(Math.ceil(filteredTimeSheets.length / countPerPage));
    }, [timeSheetSearch, arrayTimeSheets, currentPage, countPerPage]);

    useEffect(() => {
        manageTimeSheetSearch();
    }, [companyData, refreshTime, startDate, endDate, status]);

    return (
        <PageForm>
            <div className="p-3 flex flex-col w-full h-full gap-3">
                {!location.pathname.startsWith('/timeSheet/ficheHour/') && (
                    <div className={'flex gap-4'}>
                        <div className={'my-auto'}>
                            <label className='ft-sm text-gray-500'>
                                <div>
                                    Périodes
                                </div>
                                <div>
                                    <DatePicker
                                        selectsRange={true}
                                        startDate={startDate}
                                        endDate={endDate}
                                        onChange={(update) => {
                                            setDateRange(update);
                                        }}
                                        isClearable={true}
                                        className='w-full min-w-[225px] h-10 pl-3 pr-8 ft-b border rounded-lg text-center text-sm'
                                        locale='fr-FR'
                                        dateFormat="dd/MM/yyyy"
                                    />
                                </div>
                            </label>
                        </div>
                        <div className={'my-auto flex gap-2'}>
                            <div className={'w-full my-auto'}>
                                <div className={'w-full'}>
                                    <div>
                                        <label htmlFor="name" className='ft-sm text-gray-500 flex gap-1'>
                                            Recherche
                                            <Tooltip
                                                text={`La recherche se fait sur l'id du relevé d'heure, l'id bestt de l'entreprise, l'id de la mission, l'id bestt du contrat, l'id bestt de l'intérimaire, le nom de l'entreprise, le nom de l'intérimaire`}
                                                placement='right'>
                                                <div><InformationCircle wh={18} color={'#6B7280'}/></div>
                                            </Tooltip>
                                        </label>
                                        <input type="text"
                                               value={timeSheetSearch}
                                               name='lastname'
                                               className='w-full h-10 pl-3 pr-8 ft-b border rounded-lg'
                                               onChange={(e) => setTimeSheetSearch(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={'my-auto hidden'}>
                                <div>
                                    <label htmlFor="name" className='ft-sm text-gray-500 flex gap-1'>
                                        Options
                                    </label>
                                    <select className="w-full h-10 pl-2 pr-3 ft-b placeholder-gray-600 border rounded-lg"
                                            onChange={(e) => setTimeSheetSearchType(e.target.value)}
                                            value={timeSheetSearchType}
                                    >
                                        <option value="">Tous</option>
                                        <option value="id_time_sheet">ID Relevé Heure</option>
                                        <option value="id_company">ID Entreprise</option>
                                        <option value="id_mission">ID Mission</option>
                                        <option value="id_contract_bestt">ID BeSTT Contrat</option>
                                        <option value="id_bestt_temp_worker">ID BeSTT Intérimaire</option>
                                        <option value="name_company">Nom Entreprise</option>
                                        <option value="temp_worker_name">Nom Intérimaire</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className={'my-auto ml-auto'}>
                            <TimeSheetSubNav />
                        </div>
                    </div>
                )}
                <Outlet context={{
                    timeSheetsToDisplay,
                    setTimeSheetsToDisplay,
                    timeSheetSearch,
                    setTimeSheetSearch,
                    timeSheetSearchType,
                    setTimeSheetSearchType,
                    currentPage,
                    setCurrentPage,
                    totalPages,
                    setTotalPages,
                    countPerPage,
                    setCountPerPage,
                    countTotal,
                    setCountTotal,
                    isWaiting,
                    setIsWaiting,
                    dateRange,
                    setDateRange,
                    startDate,
                    endDate,
                    status,
                    setStatus
                }}/>
            </div>
        </PageForm>
    );
};

export default TimeSheet;