import PageForm from "../../components/pageForm/PageForm";
import {useEffect, useState} from "react";
import ApiDatabase from "server";
import Table from "../../components/table/table";
import Button from "../../components/button";
import {InformationCircle, Plus} from "../../assets/icons";
import useSlideOver from "../../components/SlideOver/useSlideOver";
import Tooltip from "../../components/tooltips/Tooltips";
import Pagination from "../../components/pagination/pagination";

const Company = () => {
    const [isWaiting, setIsWaiting] = useState(true);
    const [companies, setCompanies] = useState([]);
    const {toggleSlideOver} = useSlideOver();
    const [search, setSearch] = useState('')
    const [optionSearch, setOptionSearch] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [countPerPage, setCountPerPage] = useState(0)
    const [limitElement, setLimitElement] = useState(20)
    const [countTotal, setCountTotal] = useState(0)
    const [totalPages, setTotalPages] = useState(0)

    const token = localStorage.getItem('xsrfToken')

    const manageCompaniesSearch = () => {
        setIsWaiting(true)
        ApiDatabase.getCompanies({token, search, optionSearch, page: currentPage, limit: limitElement}, (data) => {
            setCompanies(data.companies)
            setTotalPages(data.countPages)
            setCountPerPage(data.countPerPage)
            setCountTotal(data.count)
            setIsWaiting(false)
        }, (err) => {
            if (err.name !== "CanceledError") {
                console.error(err);
            }
        })
    }

    useEffect(() => {
        manageCompaniesSearch()
    }, [currentPage, limitElement])

    useEffect(() => {
        manageCompaniesSearch()
    }, [])

    let timerCompanySearch = '';
    useEffect(() => {
        setCurrentPage(1)
        timerCompanySearch = setTimeout(async () => {
            await manageCompaniesSearch()
        }, 400)
        return () => clearTimeout(timerCompanySearch)
    }, [search, optionSearch])

    const titles = ['ENSEIGNE', 'GROUPE', 'REJOINT LE']

    return (
        <>
            <PageForm>
                <div className={'col-span-12 p-3 h-full'}>
                    <div className={'flex flex-col gap-8 h-full'}>
                        <div className={'flex justify-between'}>
                            <div className={'w-1/2 my-auto flex gap-2'}>
                                <div className={'w-full my-auto'}>
                                    <div className={'w-full'}>
                                        <div>
                                            <label htmlFor="name" className='ft-sm text-gray-500 flex gap-1'>
                                                Recherche
                                                <Tooltip
                                                    text={`La recherche se fait sur l'id du relevé d'heure, l'id bestt de l'entreprise, l'id de la mission, l'id bestt du contrat, l'id bestt de l'intérimaire, le nom de l'entreprise, le nom de l'intérimaire`}
                                                    placement='right'>
                                                    <div><InformationCircle wh={18} color={'#6B7280'}/></div>
                                                </Tooltip>
                                            </label>
                                            <input type="text"
                                                   value={search}
                                                   name='lastname'
                                                   className='w-full h-10 pl-3 pr-8 ft-b border rounded-lg'
                                                   onChange={(e) => setSearch(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={'my-auto'}>
                                    <div>
                                        <label htmlFor="name" className='ft-sm text-gray-500 flex gap-1'>
                                            Options
                                        </label>
                                        <select
                                            className="w-full h-10 pl-2 pr-3 ft-b placeholder-gray-600 border rounded-lg"
                                            onChange={(e) => setOptionSearch(e.target.value)}
                                            value={optionSearch}
                                        >
                                            <option value="">Tous</option>
                                            <option value="id_company">ID Entreprise</option>
                                            <option value="name_company">Nom entreprise</option>
                                            <option value="group_company">Groupe</option>
                                            <option value="employee_name">Nom Employé</option>
                                            <option value="siret">Siret</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className={'my-auto'}>
                                <Button size={'LG'} color={'PRIMARY'} onClick={() => toggleSlideOver('add_company')}>
                                    <div className={'flex gap-1'}>
                                        <div className={'my-auto'}>
                                            <Plus wh={20} color={'#FFFFFF'}/>
                                        </div>
                                        <div className={'my-auto'}>
                                            Ajouter
                                        </div>
                                    </div>
                                </Button>
                            </div>
                        </div>
                        {
                            isWaiting ? (
                                <>
                                    <table className="animate-pulse w-full animate-fadeIn">
                                        <tbody>
                                        {
                                            [1, 2, 3, 4, 5].map((i, index)=>(
                                                <tr key={index}>
                                                    {
                                                        titles?.map((title, index) => (
                                                            <td key={index} className={'px-2 py-7'}>
                                                                <div className="h-2 bg-gray-200 rounded"></div>
                                                            </td>
                                                        ))
                                                    }
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    </table>
                                </>
                            ) : (
                                <>
                                    {companies.length > 0 && (
                                        <>
                                            <Table champsBody={companies} champsHeader={titles}/>
                                            {
                                                (currentPage && totalPages > 1 && setCurrentPage) ? (
                                                    <div className={'px-8 py-4'}>
                                                        <Pagination currentPage={currentPage} countPerPage={countPerPage} countTotal={countTotal} totalPages={totalPages} setCurrentPage={setCurrentPage}/>
                                                    </div>
                                                ) : null
                                            }
                                        </>
                                    )}
                                </>
                            )
                        }
                    </div>
                </div>
            </PageForm>
        </>
    )
}

export default Company;