import ModalBody from "../../../components/body";
import Modalheader from "../../../components/header";
import useModal from "../../../useModal";
import Divider from "../../../../divider/divider";
import EmptyState from "../../../../EmptyState/EmptyState";
import axios from "axios";
import {toast} from "react-toastify";
import {useEffect, useState} from "react";
import ApiDatabase from "../../../../../server";
import UseContext from "hooks/useContext";

const ActivityVivier = () => {
    const { objID, logViviers, setLogViviers } = UseContext()
    const { toggle } = useModal()

    const token = localStorage.getItem('xsrfToken')
    //get id vivier in url (last param)
    const url = window.location.href.split('/')


    useEffect(() => {
        ApiDatabase.getLogVivier({
            token,
            idVivier: objID
        }, (data) => {
            setLogViviers(data)
        }, (err) => {
            if (err.name !== "CanceledError") {
                console.error(err)
                toast.error('Une erreur est survenue')
            }
        })
    }, [objID, token]);

    return (
        <>
            <Modalheader hide={toggle} hideShow={true}>
                Activité du vivier
            </Modalheader>
            <ModalBody>
                {
                    (Object.keys(logViviers).length > 0) ? (
                        <>
                            <div className='flex flex-col gap-4 py-3'>
                                {
                                    logViviers.map((log, index) => {
                                        const dateLogVivier = new Date(log.createdAt)
                                        const dateLogVivierFormat = `${('0' + dateLogVivier.getDate()).toString().slice(-2)}/${('0' + (dateLogVivier.getMonth() + 1)).toString().slice(-2)}/${dateLogVivier.getFullYear()}, ${('0' + dateLogVivier.getHours()).toString().slice(-2)}:${('0' + dateLogVivier.getMinutes()).toString().slice(-2)}`
                                        return (
                                            <div key={index}>
                                                <div className='flex flex-col gap-1'>
                                                    <div className={'font-bold'}>Notifié le {dateLogVivierFormat}</div>
                                                    <div className={'text-gray-500'}>par {log.employee?.firstname} {log.employee?.lastname}</div>
                                                </div>
                                                <Divider />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </>
                    ) : (
                        <EmptyState type={'log_vivier'}/>
                    )
                }
            </ModalBody>
        </>
    )
}

export default ActivityVivier;