import UseModal from "../../../../useModal";
import UseContext from "../../../../../../hooks/useContext";
import React, {useEffect, useState} from "react";
import ModalBody from "../../../../components/body";
import ModalFooter from "../../../../components/footer";
import Button from "../../../../../button";
import ApiDatabase from "../../../../../../server";
import {toast} from "react-toastify";
import DocViewer, {DocViewerRenderers} from "@cyntler/react-doc-viewer";
import {
    CardIdentity,
    CardVitale,
    CheckIcon,
    Close,
    CrossIcon,
    Localisation,
    SelfieIdentity
} from "../../../../../../assets/icons";
import Banner from "../../../../../banner/banner";
import {compareObjects, dateToEpoch} from "../../../../../../helper/helper";
import AddressGeoApi from "../../../../../AddressGeoApi/AddressGeoApi";
import Usefetchdata from "../../../../../../hooks/usefetchdata";

const AdminModalFileAcceptSimple = () => {
    const { toggle } = UseModal();
    const { adminLink, adminType, adminTempWorkerId, setUpdateTempWorker, setOpenModal } = UseContext();
    const { userData } = Usefetchdata();
    const [tempWorkerInfos, setTempWorkerInfos] = useState({
        tempWorker: {},
        user: {},
    });
    const [address, setAddress] = useState('');
    const links = Object.values(adminLink);
    const token = localStorage.getItem('xsrfToken');
    const [docs, setDocs] = useState([]);
    const [viewerStyle, setViewerStyle] = useState({ maxHeight: 700, pdfVerticalScrollByDefault: true });
    const [isFileExist, setIsFileExist] = useState(false);
    const [isWaitingFile, setIsWaitingFile] = useState(false);

    useEffect(() => {
        if (userData._id) {
            manageFiles();
        }
    }, [adminLink, userData]);

    const manageFiles = async () => {
        if (adminLink) {
            setIsWaitingFile(true);
            for (const [key, value] of Object.entries(adminLink)) {
                if (value) {
                    const response = await checkFileExistence(value);
                    setIsFileExist(response);
                    if (response) {
                        const buffer = await fetch(value).then(res => res.arrayBuffer());
                        const blob = new Blob([buffer], { type: response.type });
                        const fileURL = URL.createObjectURL(blob);
                        setDocs(docs => [...docs, { uri: fileURL, fileType: response.type }]);
                    }
                }
            }
            setIsWaitingFile(false);
        }
    };

    const checkFileExistence = async (fileUrl) => {
        try {
            const response = await fetch(fileUrl);
            if (response.status >= 200 && response.status < 300) {
                const contentType = response.headers.get('content-type');
                if (contentType.includes('pdf') || contentType.includes('image')) {
                    const buffer = await response.arrayBuffer();
                    const blob = new Blob([buffer], { type: contentType });

                    return blob.size !== 0 ? { type: contentType } : false;
                }
                return false;
            }
            return false;
        } catch (error) {
            console.error("Error checking file existence:", error);
            return false;
        }
    };

    const type = {
        'identity': {
            text: 'Pièce d\'identité',
            icon: <CardIdentity wh={24} color={'#6B7280'}/>,
        },
        'selfie': {
            text: 'Selfie d\'identité',
            icon: <SelfieIdentity wh={24} color={'#6B7280'}/>,
        },
        'visa': {
            text: 'Visa',
            icon: <CardIdentity wh={24} color={'#6B7280'}/>,
        },
        'card_vitality': {
            text: 'Carte Vitalité',
            icon: <CardVitale wh={24} color={'#6B7280'}/>,
        },
        'proof_of_address': {
            text: 'Justificatif de domicile',
            icon: <Localisation wh={24} color={'#6B7280'}/>,
        },
        'accommodation_of_address': {
            text: 'Attestation de logement',
            icon: <Localisation wh={24} color={'#6B7280'}/>,
        },
    };

    const modalAcceptSubmit = (e) => {
        e.preventDefault();
        if (token && adminTempWorkerId && adminType) {
            if (adminType === 'proof_of_address' || adminType === 'accommodation_of_address') {
                if (Object.keys(address).length === 0) {
                    toast.error('Veuillez remplir l\'adresse');
                    return;
                }
            }
            ApiDatabase.postAcceptTempWorkerFileAdmin({ token, adminTempWorkerId, adminType },
                (data) => {
                    toast.success('Fichier accepté avec succès');
                    toggle();
                    setUpdateTempWorker(data._id);
                },
                (err) => {
                    if (err.name !== "CanceledError") {
                        console.log(err);
                    }
                }
            );
        } else {
            toast.error('Une erreur est survenue, veuillez réessayer ou contacter le support');
        }
    };

    useEffect(() => {
        if (userData._id) {
            if (token && adminTempWorkerId) {
                ApiDatabase.getTempWorkerById({ token, adminTempWorkerId },
                    (data) => {
                        setTempWorkerInfos(prevData => ({
                            ...prevData,
                            tempWorker: data,
                        }));
                        const id_user = data.id_user;
                        ApiDatabase.getUserById({ token, id_user },
                            (data) => {
                                const birthDay = dateToEpoch(data.birthday);
                                setTempWorkerInfos(prevData => ({
                                    ...prevData,
                                    user: {
                                        ...data,
                                        birthday: (('0' + birthDay.getDate()).toString().slice(-2)) + '/' + (('0' + (parseInt(birthDay.getMonth()) + 1)).toString().slice(-2)) + '/' + birthDay.getFullYear(),
                                    },
                                }));
                            },
                            (err) => {
                                if (err.name !== "CanceledError") {
                                    console.log(err);
                                }
                            });
                    },
                    (err) => {
                        if (err.name !== "CanceledError") {
                            console.log(err);
                        }
                    });
            } else {
                setTempWorkerInfos({
                    tempWorker: {},
                    user: {},
                });
                toast.error('Une erreur est survenue, veuillez réessayer ou contacter le support');
            }
        }
    }, [adminTempWorkerId, userData]);

    if (adminType === 'proof_of_address' || adminType === 'accommodation_of_address') {
        if (tempWorkerInfos.tempWorker?.addresses?.length > 0) {
            ApiDatabase.getAddress({ address: tempWorkerInfos.tempWorker.addresses },
                (data) => {
                    tempWorkerInfos.tempWorker.addresses = data;
                    setAddress(data);
                },
                (err) => {
                    if (err.name !== "CanceledError") {
                        console.error(err);
                    }
                })
        }

        useEffect(() => {
            if (!compareObjects(tempWorkerInfos.tempWorker?.addresses, address) && Object.keys(address).length > 0) {
                ApiDatabase.postUpdateAddressTempWorker(
                    {
                        token: token,
                        idUser: tempWorkerInfos.user._id,
                        address: address
                    },
                    (data) => {
                        toast.success('L\'adresse a été modifiée.');
                    }
                );
            }
        }, [address]);
    }


    return (
        <>
            <ModalBody>
                <div className={'flex gap-3'}>
                    <div className={'w-2/3'}>
                        {isWaitingFile ? (
                            <>
                                Chargement du document...
                            </>
                        ) : (
                            <>
                                {
                                    isFileExist ? (
                                        docs && (
                                            <DocViewer documents={docs} style={viewerStyle} pluginRenderers={DocViewerRenderers}/>
                                        )
                                    ) : (
                                        <div className={'flex justify-center items-center h-full py-3 px-1 border rounded'}>
                                            <div className={'text-gray-500'}>
                                                Une erreur est survenue lors du chargement du document. Le fichier est peut-être corrompu ou inexistant.
                                            </div>
                                        </div>
                                    )
                                }
                            </>
                        )}
                    </div>
                    <div className={'w-1/3 flex flex-col gap-3'}>
                        <div className={'w-full'}>
                            <div className={'flex justify-between gap-5'}>
                                <div className={'w-full'}>
                                    <div className={'p-3 border rounded flex gap-3'}>
                                        <div className={'my-auto'}>
                                            {type[adminType].icon}
                                        </div>
                                        <div className={'my-auto ft-sm'}>
                                            <div className={''}>
                                                {adminType === 'identity' && (tempWorkerInfos.tempWorker.identity?.type === 'Passeport' ? 'Passeport' : 'Carte d\'identité')}
                                                {adminType === 'visa' && (tempWorkerInfos.tempWorker.visa?.type === 'Visa' ? 'Visa' : 'Titre de séjour')}
                                                {(adminType !== 'identity' && adminType !== 'visa') && type[adminType].text}
                                            </div>
                                            <div className={'text-gray-500'}>
                                                {
                                                    docs.length === 2 ? 'Recto & verso' : (docs.length > 2 ? 'Plusieurs documents' : 'Document unique')
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={''}>
                                    <div className={'cursor-pointer'} onClick={toggle}>
                                        <CrossIcon wh={20} color={'#374151'}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'w-full flex flex-col py-3 gap-6'}>
                            <div className={'grid grid-cols-2 gap-4 ft-sm'}>
                                {
                                    adminType === 'accommodation_of_address' && (
                                        <>
                                            {
                                                tempWorkerInfos.tempWorker.accommodation_of_address?.validated === '2' && (
                                                    <div className={'col-span-2'}>
                                                        <Banner type={'info'}>
                                                            Le document est validé.
                                                        </Banner>
                                                    </div>
                                                )
                                            }
                                            {
                                                tempWorkerInfos.tempWorker.accommodation_of_address?.validated === '3' && (
                                                    <div className={'col-span-2'}>
                                                        <Banner type={'alert'}>
                                                            Le document a été refusé. Le candidat a déjà été notifié.
                                                        </Banner>
                                                    </div>
                                                )
                                            }
                                        </>
                                    )
                                }
                                {
                                    adminType === 'proof_of_address' && (
                                        <>
                                            {
                                                tempWorkerInfos.tempWorker.proof_of_address?.validated === '2' && (
                                                    <div className={'col-span-2'}>
                                                        <Banner type={'info'}>
                                                            Le document est validé.
                                                        </Banner>
                                                    </div>
                                                )
                                            }
                                            {
                                                tempWorkerInfos.tempWorker.proof_of_address?.validated === '3' && (
                                                    <div className={'col-span-2'}>
                                                        <Banner type={'alert'}>
                                                            Le document a été refusé. Le candidat a déjà été notifié.
                                                        </Banner>
                                                    </div>
                                                )
                                            }
                                        </>
                                    )
                                }
                                <div className={''}>Nom</div>
                                <div className={'text-gray-500'}>{tempWorkerInfos.user.lastname}</div>
                                <div className={''}>Prenom</div>
                                <div className={'text-gray-500'}>{tempWorkerInfos.user.firstname}</div>
                            </div>
                            {
                                (adminType === 'proof_of_address' || adminType === 'accommodation_of_address') && (
                                    <div className={'w-100'}>
                                        <AddressGeoApi
                                            addressGeoValue={setAddress}
                                            addressValue={address}
                                            page={'profileAddress'}
                                        />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button size={'LG'} color={'SECONDARY'} onClick={() => {
                    toggle()
                    setOpenModal('ad_file_refuse')
                }}>
                    <div className={'flex gap-2'}>
                        <div className={'my-auto'}>
                            <Close wh={20} color={'#374151'}/>
                        </div>
                        <div className={'my-auto'}>
                            Refuser
                        </div>
                    </div>
                </Button>
                <Button size={'LG'} color={'PRIMARY'} onClick={modalAcceptSubmit}>
                    <div className={'flex gap-2'}>
                        <div className={'my-auto'}>
                            <CheckIcon w={14} h={10} color={'#ffff'}/>
                        </div>
                        <div className={'my-auto'}>
                            Accepter
                        </div>
                    </div>
                </Button>
            </ModalFooter>
        </>
    );
}

export default AdminModalFileAcceptSimple;