import {createContext, useState, useEffect, useRef} from "react";

import {LangFr} from "assets/locales/fr";
import {LangEn} from "assets/locales/en";
import {Language as LanguageManager} from "assets/locales/fLang";
import {useLocation} from "react-router-dom";
import ApiDatabase from "../server";

const roadContext = createContext({})

function ContextProvider({children}) {
    // *******   Section Sidebar   *******
    const storedSidebarExpanded = localStorage.getItem("sidebar-expanded");
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [sidebarExpanded, setSidebarExpanded] = useState(
        storedSidebarExpanded === null ? false : storedSidebarExpanded === "true"
    );
    // *******   Section Refresh   *******
    const [refreshUser, setRefreshUser] = useState(false)
    const refreshLoadUser = () => {
        setRefreshUser(!refreshUser)
    }
    const [refreshWorker, setRefreshWorker] = useState(false)
    const refreshLoadWorker = () => {
        setRefreshWorker(!refreshWorker)
    }
    // *******   Section Language   *******
    //    instancie le manager
    const language = new LanguageManager()
    //    charge les fichiers de langues (ils seront chargés qu'une fois)
    language.loadLanguage(LangFr)
    language.loadLanguage(LangEn)
    //    spécifie la langue en cours
    language.setLanguage('fr')

    // *******   Section Modal   *******
    const [modalShow, setModalShow] = useState(false)
    const [modalType, setModalType] = useState('')
    const [modalData, setModalData] = useState({});
    const [modalDataReturn, setModalDataReturn] = useState({});

    // *******   Section URL   *******
    const [slideOverShow, setSlideOverShow] = useState(false)
    const [slideOverType, setSlideOverType] = useState('')

    // *******   Section URL   *******
    const route = useLocation()
    const {pathname} = route
    //1 - interimaire 2- entreprise
    const [typeNav, setTypeNav] = useState(1)
    const [email, setEmail] = useState('')
    const [name, setName] = useState({
        firstname: '',
        lastname: ''
    })
    useEffect(() => {
            if (pathname.includes('entreprise')) setTypeNav(2)
            else setTypeNav(1)
        }
        , [pathname])

    const [auth, setAuth] = useState({})
    const updateUser = (pToken = '-1', pRole) => {
        if (pToken !== '-1') {
            localStorage.setItem('xsrfToken', pToken)
            localStorage.setItem('rUser', pRole)
        }
    }


    // *******   Section Time Sheets   *******
    const [timeData, setTimeData] = useState({
        id:'',
        id_time_sheet:'',
        index: '',
        day_hours_ent:'',
        periode_one_start:'',
        periode_one_end:'',
        periode_two_start:'',
        periode_two_end:'',
        periode_three_start:'',
        periode_three_end:'',
    })
    const [totalH, setTotalH] = useState(0)
    const [refreshTime,setRefreshTime]=useState(false)
    const [timeCheck, setTimeCheck] =useState( {})
    const [content, setContent]=useState('')
    // *******   Section OBJ   *******
    const [expID, setExpID] = useState('')
    const [objID, setObjID] = useState('')
    const [objType, setObjType] = useState('')
    const [adminLink, setAdminLink] = useState('')
    const [adminTempWorkerId, setAdminTempWorkerId] = useState('')
    const [adminType, setAdminType] = useState('')
    const [updateTempWorker, setUpdateTempWorker] = useState(false)

    const [coeffs, setCoeffs] = useState([])

    // #region **** Vivier ****
    const [infoVivier,setInfoVivier] = useState()
    const [listVivier,setListVivier] = useState()
    const [refreshVivierLog,setRefreshVivierLog] = useState(true)
    // #endregion **** Vivier ****

    const [userUnAssigned, setUserUnAssigned] = useState(false)

    const [currentPageTimeSheetToBeChecked, setCurrentPageTimeSheetToBeChecked] = useState(1)
    const [timeSheetSearchTimeSheetToBeChecked, setTimeSheetSearchTimeSheetToBeChecked] = useState('')
    const [timeSheetSearchTypeTimeSheetToBeChecked, setTimeSheetSearchTypeTimeSheetToBeChecked] = useState('')
    const [dateRangeTimeSheetToBeChecked, setDateRangeTimeSheetToBeChecked] = useState([null, null])

    const [currentPageTimeSheetChecked, setCurrentPageTimeSheetChecked] = useState(1)
    const [timeSheetSearchTimeSheetChecked, setTimeSheetSearchTimeSheetChecked] = useState('')
    const [timeSheetSearchTypeTimeSheetChecked, setTimeSheetSearchTypeTimeSheetChecked] = useState('')
    const [dateRangeTimeSheetChecked, setDateRangeTimeSheetChecked] = useState([null, null])

    const [currentPageTimeSheetLitigation, setCurrentPageTimeSheetLitigation] = useState(1)
    const [timeSheetSearchTimeSheetLitigation, setTimeSheetSearchTimeSheetLitigation] = useState('')
    const [timeSheetSearchTypeTimeSheetLitigation, setTimeSheetSearchTypeTimeSheetLitigation] = useState('')
    const [dateRangeTimeSheetLitigation, setDateRangeTimeSheetLitigation] = useState([null, null])

    const [openModal, setOpenModal] = useState(false)

    const [missionDetails, setMissionDetails] = useState({})
    const [scheduleWeeks, setScheduleWeeks] = useState([])
    const [updSchedule, setUpdSchedule] = useState(false)
    const [activeScheduleWeekDay, setActiveScheduleWeekDay] = useState([])
    const [scheduleWeeksNew, setScheduleWeeksNew] = useState([])
    const [refreshMissionDetails, setRefreshMissionDetails] = useState({})
    
    const [refreshCompanyBigAccount, setRefreshCompanyBigAccount] = useState(false)
    
    const [reviewUntreatedDelete, setReviewUntreatedDelete] = useState(false)
    const [refreshUntreated, setRefreshUntreated] = useState(false)

    const [refreshQualifications, setRefreshQualifications] = useState(false)
    const [refreshQualification, setRefreshQualification] = useState(false)

    const [usersVivier, setUsersVivier] = useState([])

    const [userVivierToArchive, setUserVivierToArchive] = useState({})
    const [userVivierToArchiveUpdated, setUserVivierToArchiveUpdated] = useState({})

    const [commentID, setCommentID] = useState('')
    const [commentIDUpd, setCommentIDUpd] = useState('')

    const [userData, setUserData] = useState({})
    const [tempWorkerData, setTempWorkerData] = useState({})

    const [tempWorkersWithIdentityFilesToComplete, setTempWorkersWithIdentityFilesToComplete] = useState([]);

    const [logViviers, setLogViviers] = useState({})
    const [lastLogVivier, setLastLogVivier] = useState('')
    
    const [totalPagesUsersVivier, setTotalPagesUsersVivier] = useState(0)
    const [countPerPageUsersVivier, setCountPerPageUsersVivier] = useState(0)
    const [countTotalUsersVivier, setCountTotalUsersVivier] = useState(0)
    const [globalUsersVivier, setGlobalUsersVivier] = useState()
    const [dataUsersVivier, setDataUsersVivier] = useState()
    const [resultUsersVivier, setResultUsersVivier] = useState()
    const [result2UsersVivier, setResult2UsersVivier] = useState()
    const [vivierUsersVivier, setVivierUsersVivier] = useState()
    const [isVivierLinkUsersVivier, setIsVivierLinkUsersVivier] = useState(true)

    const [resultUsersVivierAll, setResultUsersVivierAll] = useState([])

    const [totalPagesVivierAllPage, setTotalPagesVivierAllPage] = useState(0)
    const [countPerPageVivierAllPage, setCountPerPageVivierAllPage] = useState(0)
    const [countTotalVivierAllPage, setCountTotalVivierAllPage] = useState(0)
    const [dataVivierAllPage, setDataVivierAllPage] = useState()
    const [resultVivierAllPage, setResultVivierAllPage] = useState()

    const [vivierAllHeaderVivier, setVivierAllHeaderVivier] = useState()
    const [nbAllViviersHeaderVivier, setNbAllViviersHeaderVivier] = useState(0);
    const [viviersRatingTempWorkerValidation, setViviersRatingTempWorkerValidation] = useState([])
    const [vivierAllAddVivier, setVivierAllAddVivier] = useState()

    const [totalPagesVivierListUser, setTotalPagesVivierListUser] = useState(0)
    const [countTotalVivierListUser, setCountTotalVivierListUser] = useState(0)
    const [dataVivierListUser, setDataVivierListUser] = useState()
    const [resultVivierListUser, setResultVivierListUser] = useState()

    const [searchMissionResultMissionPeriod, setSearchMissionResultMissionPeriod] = useState(false);
    const [totalPagesMissionPeriod, setTotalPagesMissionPeriod] = useState(0)
    const [countTotalMissionPeriod, setCountTotalMissionPeriod] = useState(0)

    const [infoMissionDetails, setInfoMissionDetails] = useState(false)

    const [scheduleWeeksProvScheduleByIdMission, setScheduleWeeksProvScheduleByIdMission] = useState([])

    const [searchTempWorkerInviteResultTempWorkersInviteMission, setSearchTempWorkerInviteResultTempWorkersInviteMission] = useState({users_invite: []})

    const [searchTempWorkerAssignationResultTempWorkerPostulateMission, setSearchTempWorkerAssignationResultTempWorkerPostulateMission] = useState(false)
    const [totalPagesTempWorkerAssignationTempWorkerPostulateMission, setTotalPagesTempWorkerAssignationTempWorkerPostulateMission] = useState(0)
    const [countTotalTempWorkerAssignationTempWorkerPostulateMission, setCountTotalTempWorkerAssignationTempWorkerPostulateMission] = useState(0)

    const removeAllParameters = () => {
        const params = new URLSearchParams(window.location.search);

        const urlWithoutParams = window.location.origin + window.location.pathname;
        window.history.replaceState({}, document.title, urlWithoutParams);
    }

    const getCurrentPageValues = (arrayElement, currentPage, limitElement) => {
        if (!Array.isArray(arrayElement) || currentPage < 1 || limitElement < 1) {
            return [];
        }

        const startIndex = (currentPage - 1) * limitElement;
        const currentPageValues = arrayElement.slice(startIndex, startIndex + limitElement);

        return currentPageValues;
    }

    const socketRef = useRef(null);
    useEffect(() => {
        const manageSocket = async () => {
            if (!socketRef.current) {
                socketRef.current =
                    await ApiDatabase.connectSocketWithToken();
                if (socketRef.current) {
                    const cache = await ApiDatabase.getCache();
                    const token = localStorage.getItem("xsrfToken");

                    const handleTempWorkerChange = (change, action) => {
                        const cacheKeyTempWorkersWithIdentityFilesToComplete = `post_admin/tempWorkersWithIdentityFilesToComplete_{"token":"${token}"}`;
                        let cachedDataTempWorkersWithIdentityFilesToComplete = cache.get(cacheKeyTempWorkersWithIdentityFilesToComplete);
                        const cacheKeyVivierListUser = `get_vivier/vivierListUser?page=1&limit=25&search=&optionSearch=&profil=false&filterOptions={"web":true,"app":true,"martinique":true,"guadeloupe":true,"other":true,"a_z":false,"recent":false}_{"page":1,"limit":25,"search":"","optionSearch":"","profil":false,"filterOptions":"{\\"web\\":true,\\"app\\":true,\\"martinique\\":true,\\"guadeloupe\\":true,\\"other\\":true,\\"a_z\\":false,\\"recent\\":false}"}`
                        let cachedDataVivierListUser = cache.get(cacheKeyVivierListUser);

                        if (cachedDataTempWorkersWithIdentityFilesToComplete) {
                            if (change.length > 0) {
                                setTempWorkersWithIdentityFilesToComplete(change);

                                cachedDataTempWorkersWithIdentityFilesToComplete = change
                            }
                        }

                        if (cachedDataVivierListUser) {
                            if (change.users) {
                                setTotalPagesVivierListUser(change.countPages)
                                setCountTotalVivierListUser(change.count)
                                setDataVivierListUser(change.count)
                                setResultVivierListUser(change.users)

                                cachedDataVivierListUser = change
                            }
                        }

                        cache.set(cacheKeyTempWorkersWithIdentityFilesToComplete, cachedDataTempWorkersWithIdentityFilesToComplete);
                        cache.set(cacheKeyVivierListUser, cachedDataVivierListUser);
                    }

                    const handleLogVivierChange = (change, action) => {
                        const cacheKeyLogVivier = `get_vivier/logVivier?token=${token}&idVivier=${change[0].id_vivier}_{"token":"${token}","idVivier":"${change[0].id_vivier}"}`;
                        let cachedDataLogVivier = cache.get(cacheKeyLogVivier);

                        if (cachedDataLogVivier) {
                            cachedDataLogVivier = change
                        }

                        setLogViviers(change);
                        const dateLogVivier = new Date(change[0].createdAt)
                        const dateLogVivierFormat = `${('0' + dateLogVivier.getDate()).toString().slice(-2)}/${('0' + (dateLogVivier.getMonth() + 1)).toString().slice(-2)}/${dateLogVivier.getFullYear()} à ${('0' + dateLogVivier.getHours()).toString().slice(-2)}:${('0' + dateLogVivier.getMinutes()).toString().slice(-2)}`
                        setLastLogVivier(`Notifié le ${dateLogVivierFormat}`)

                        cache.set(cacheKeyLogVivier, cachedDataLogVivier);
                    }

                    const handleVivierChange = (change, action) => {
                        const cacheKeyUsersVivier = `get_vivier/vivierFiche?page=1&limit=25&search=&optionSearch=&profil=false&id=${change.vivier?._id}&filterOptions={"web":true,"app":true,"martinique":true,"guadeloupe":true,"other":true,"a_z":false,"recent":false}_{"page":1,"limit":25,"search":"","optionSearch":"","profil":false,"id":"${change.vivier?._id}","filterOptions":"{\\"web\\":true,\\"app\\":true,\\"martinique\\":true,\\"guadeloupe\\":true,\\"other\\":true,\\"a_z\\":false,\\"recent\\":false}"}`
                        let cachedDataUsersVivier = cache.get(cacheKeyUsersVivier);
                        const cacheKeyUsersVivierAll = `get_vivier/vivierFiche?page=1&limit=9999&search=&optionSearch=&profil=false&id=${change.vivier?._id}&filterOptions={}_{"page":1,"limit":9999,"search":"","optionSearch":"","profil":false,"id":"${change.vivier?._id}","filterOptions":"{}"}`
                        let cachedDataUsersVivierAll = cache.get(cacheKeyUsersVivierAll);
                        const cacheKeyVivierAllPage = `get_vivier/vivierAllPage?token=${token}&page=1&limit=25&GP=false&MQ=false&search=&sortName=false_{"token":"${token}","currentPage":1,"limitElement":25,"selectGP":false,"selectMQ":false,"search":"","sortName":false}`
                        let cachedDataVivierAllPage = cache.get(cacheKeyVivierAllPage);
                        const cacheKeyVivierAll = `get_vivier/vivierAll_{}`
                        let cachedDataVivierAll = cache.get(cacheKeyVivierAll);
                        const cacheKeyVivierListUser = `get_vivier/vivierListUser?page=1&limit=25&search=&optionSearch=&profil=false&filterOptions={"web":true,"app":true,"martinique":true,"guadeloupe":true,"other":true,"a_z":false,"recent":false}_{"page":1,"limit":25,"search":"","optionSearch":"","profil":false,"filterOptions":"{\\"web\\":true,\\"app\\":true,\\"martinique\\":true,\\"guadeloupe\\":true,\\"other\\":true,\\"a_z\\":false,\\"recent\\":false}"}`
                        let cachedDataVivierListUser = cache.get(cacheKeyVivierListUser);

                        if (cachedDataUsersVivier) {
                            if (change.vivier) {
                                setCountPerPageUsersVivier(25)
                                setCountTotalUsersVivier(change.count)
                                setDataUsersVivier(change.count)
                                setResultUsersVivier(change.users)
                                const userResult2 = getCurrentPageValues(change.users, 1, 25)
                                setResult2UsersVivier(userResult2)
                                setTotalPagesUsersVivier(Math.ceil(userResult2.length / 25))
                                setVivierUsersVivier(change.vivier)
                                setIsVivierLinkUsersVivier(change.vivier.qualification?.id_qualification ?? false)
                                setGlobalUsersVivier(change.vivier?.user_preferences)

                                cachedDataUsersVivier = change
                            }
                        }

                        if (cachedDataUsersVivierAll) {
                            if (change.vivier) {
                                setResultUsersVivierAll(change.users)

                                cachedDataUsersVivierAll = change
                            }
                        }

                        if (cachedDataVivierAllPage) {
                            if (change.viviers) {
                                setTotalPagesVivierAllPage(change.countPages)
                                setCountTotalVivierAllPage(change.count)
                                setCountPerPageVivierAllPage(change.countPerPage)
                                setDataVivierAllPage(change.count);
                                setResultVivierAllPage(change.viviers)

                                cachedDataVivierAllPage = change
                            }
                        }

                        if (cachedDataVivierAll) {
                            if (change.length > 0 && change[0]?.qualification?.id_qualification) {
                                cachedDataVivierAll = change

                                setVivierAllHeaderVivier(change)
                                setNbAllViviersHeaderVivier(change.length)
                                setListVivier(vivierAllHeaderVivier)
                                setViviersRatingTempWorkerValidation(change)
                                setVivierAllAddVivier(change);
                            }
                        }

                        if (cachedDataVivierListUser) {
                            if (change.users && !change.vivier) {
                                setTotalPagesVivierListUser(change.countPages)
                                setCountTotalVivierListUser(change.count)
                                setDataVivierListUser(change.count)
                                setResultVivierListUser(change.users)

                                cachedDataVivierListUser = change
                            }
                        }

                        cache.set(cacheKeyUsersVivier, cachedDataUsersVivier);
                        cache.set(cacheKeyUsersVivierAll, cachedDataUsersVivierAll);
                        cache.set(cacheKeyVivierAllPage, cachedDataVivierAllPage);
                        cache.set(cacheKeyVivierAll, cachedDataVivierAll);
                        cache.set(cacheKeyVivierListUser, cachedDataVivierListUser);
                    }

                    const handleMissionChange = (change, action) => {
                        const cacheKeySearchMission = `post_admin/searchMission_{"token":"${token}","search":[""],"isMissionSearchAssigned":null,"isMissionSearchSigned":null,"isMissionSearchSignatureSend":null,"statusMission":0,"limit":20,"page":1}`
                        let cachedDataSearchMission = cache.get(cacheKeySearchMission);
                        const cacheKeyMissionDetailsById = `get_admin/getMissionDetailsById?token=${token}&idMission=${change?._id}_{}`
                        let cachedDataMissionDetailsById = cache.get(cacheKeyMissionDetailsById);
                        const cacheKeyScheduleByIdMission = `get_admin/getScheduleByIdMission?token=${token}&idMission=${change[0]?.id_mission}_{"token":"${token}","idMission":"${change[0]?.id_mission}"}`
                        let cachedDataScheduleByIdMission = cache.get(cacheKeyScheduleByIdMission);
                        const cacheKeyTempWorkersInviteMission = `get_admin/getTempWorkerInviteMission?token=${token}&idMission=${change?.id_mission}_{"token":"${token}","idMission":"${change?.id_mission}"}`
                        let cachedDataTempWorkersInviteMission = cache.get(cacheKeyTempWorkersInviteMission);
                        const cacheKeyTempWorkerPostulateMission = `post_admin/getTempWorkerPostulateMission_{"token":"${token}","search":[],"idMission":"${cache.id_mission}","limit":20,"page":1}`
                        let cachedDataTempWorkerPostulateMission = cache.get(cacheKeyTempWorkerPostulateMission);

                        if (cachedDataSearchMission) {
                            if (change?.missions?.length > 0) {
                                setSearchMissionResultMissionPeriod(change.missions)
                                setTotalPagesMissionPeriod(change.countPages)
                                setCountTotalMissionPeriod(change.count)

                                cachedDataSearchMission = change
                            }
                        }

                        if (cachedDataMissionDetailsById) {
                            if (change?._id && change?.title) {
                                setInfoMissionDetails(prev => {
                                    return (prev && prev._id === change._id) ? change : prev;
                                })

                                cachedDataMissionDetailsById = change
                            }
                        }

                        if (cachedDataScheduleByIdMission) {
                            if (change[0]?._id && change[0].days.length > 0) {
                                setScheduleWeeksProvScheduleByIdMission(prev => {
                                    return prev.map(item => {
                                        const changeItem = change.find(c => c._id === item._id);
                                        if (changeItem) {
                                            return changeItem;
                                        }
                                        return item;
                                    });
                                });

                                cachedDataScheduleByIdMission = change
                            }
                        }

                        if (cachedDataTempWorkersInviteMission) {
                            if (change?.users_invite?.length > 0 && change?.id_mission) {
                                //if id_mission is the same as the current mission
                                setSearchTempWorkerInviteResultTempWorkersInviteMission(prev => {
                                    if (prev && prev.id_mission === change.id_mission) return change
                                    return prev
                                })

                                cachedDataTempWorkersInviteMission = change
                            }
                        }

                        if (cacheKeyTempWorkerPostulateMission) {
                            if ((change?.users?.complete || change?.users?.partial || change?.users?.not_updated || change?.users?.incomplete) && change?.id_mission) {
                                //if id_mission is the same as the current mission
                                setSearchTempWorkerAssignationResultTempWorkerPostulateMission(prev => {
                                    if (prev && prev.id_mission === change.id_mission) return change
                                    return prev
                                })

                                cachedDataTempWorkerPostulateMission = change
                            }
                        }

                        cache.set(cacheKeySearchMission, cachedDataSearchMission);
                        cache.set(cacheKeyMissionDetailsById, cachedDataMissionDetailsById);
                        cache.set(cacheKeyScheduleByIdMission, cachedDataScheduleByIdMission);
                        cache.set(cacheKeyTempWorkersInviteMission, cachedDataTempWorkersInviteMission);
                    }

                    const handleScheduleChange = (change, action) => {
                        const cacheKeyScheduleByIdMission = `get_admin/getScheduleByIdMission?token=${token}&idMission=${change[0]?.id_mission}_{"token":"${token}","idMission":"${change[0]?.id_mission}"}`
                        let cachedDataScheduleByIdMission = cache.get(cacheKeyScheduleByIdMission);
                        console.log('cacheKeyScheduleByIdMission', cacheKeyScheduleByIdMission)
                        console.log('change', change)

                        if (cachedDataScheduleByIdMission) {
                            console.log('cachedDataScheduleByIdMission', cachedDataScheduleByIdMission)
                            console.log('change', change[0]?._id, change[0]?.days?.length > 0)
                            if (change[0]?._id && change[0]?.days?.length > 0) {
                                setScheduleWeeksProvScheduleByIdMission(prev => {
                                    return prev.map(item => {
                                        const changeItem = change.find(c => c._id === item._id);
                                        if (changeItem) {
                                            return changeItem;
                                        }
                                        return item;
                                    });
                                });

                                cachedDataScheduleByIdMission = change
                            }
                        }

                        cache.set(cacheKeyScheduleByIdMission, cachedDataScheduleByIdMission);
                    }

                    socketRef.current.on("tempWorkerCreated", (change) =>
                        handleTempWorkerChange(change, "create"),
                    );
                    socketRef.current.on("tempWorkerUpdated", (change) =>
                        handleTempWorkerChange(change, "update"),
                    );
                    socketRef.current.on("tempWorkerDeleted", (change) =>
                        handleTempWorkerChange(change, "delete"),
                    );
                    socketRef.current.on("logVivierCreated", (change) =>
                        handleLogVivierChange(change, "create"),
                    );
                    socketRef.current.on("logVivierUpdated", (change) =>
                        handleLogVivierChange(change, "update"),
                    );
                    socketRef.current.on("logVivierDeleted", (change) =>
                        handleLogVivierChange(change, "delete"),
                    );
                    socketRef.current.on("vivierUpdated", (change) =>
                        handleVivierChange(change, "update"),
                    );
                    socketRef.current.on("missionCreated", (change) =>
                        handleMissionChange(change, "create"),
                    );
                    socketRef.current.on("missionUpdated", (change) =>
                        handleMissionChange(change, "update"),
                    );
                    socketRef.current.on("missionDeleted", (change) =>
                        handleMissionChange(change, "delete"),
                    );
                    socketRef.current.on("scheduleCreated", (change) =>
                        handleScheduleChange(change, "create"),
                    );
                    socketRef.current.on("scheduleUpdated", (change) =>
                        handleScheduleChange(change, "update"),
                    );
                    socketRef.current.on("scheduleDeleted", (change) =>
                        handleScheduleChange(change, "delete"),
                    );
                }
            }
        };
        manageSocket();

        return () => {
            if (socketRef.current) {
                socketRef.current.disconnect();
                socketRef.current = null;
            }
        };
    }, []);

    return (
        <roadContext.Provider value={{
            language,
            modalShow, setModalShow, modalType, setModalType,
            modalData, setModalData, modalDataReturn, setModalDataReturn,
            slideOverShow, setSlideOverShow, slideOverType, setSlideOverType,
            typeNav,
            email, setEmail,
            name, setName,
            updateUser,
            auth, setAuth,
            refreshUser, setRefreshUser, refreshLoadUser,
            refreshWorker, setRefreshWorker, refreshLoadWorker,
            sidebarOpen, setSidebarOpen,
            sidebarExpanded, setSidebarExpanded,
            expID, setExpID,
            objID, setObjID, objType, setObjType,
            userUnAssigned, setUserUnAssigned,
            adminLink, setAdminLink,
            adminTempWorkerId, setAdminTempWorkerId,
            adminType, setAdminType,
            updateTempWorker, setUpdateTempWorker,
            timeData, setTimeData,refreshTime,setRefreshTime,timeCheck, setTimeCheck, content, setContent, totalH, setTotalH,
            coeffs, setCoeffs,
            infoVivier,setInfoVivier,listVivier,setListVivier,refreshVivierLog,setRefreshVivierLog,

            currentPageTimeSheetToBeChecked, setCurrentPageTimeSheetToBeChecked,
            timeSheetSearchTimeSheetToBeChecked, setTimeSheetSearchTimeSheetToBeChecked,
            timeSheetSearchTypeTimeSheetToBeChecked, setTimeSheetSearchTypeTimeSheetToBeChecked,
            dateRangeTimeSheetToBeChecked, setDateRangeTimeSheetToBeChecked,

            currentPageTimeSheetChecked, setCurrentPageTimeSheetChecked,
            timeSheetSearchTimeSheetChecked, setTimeSheetSearchTimeSheetChecked,
            timeSheetSearchTypeTimeSheetChecked, setTimeSheetSearchTypeTimeSheetChecked,
            dateRangeTimeSheetChecked, setDateRangeTimeSheetChecked,

            currentPageTimeSheetLitigation, setCurrentPageTimeSheetLitigation,
            timeSheetSearchTimeSheetLitigation, setTimeSheetSearchTimeSheetLitigation,
            timeSheetSearchTypeTimeSheetLitigation, setTimeSheetSearchTypeTimeSheetLitigation,
            dateRangeTimeSheetLitigation, setDateRangeTimeSheetLitigation,

            openModal, setOpenModal,

            missionDetails, setMissionDetails,
            scheduleWeeks, setScheduleWeeks,
            updSchedule, setUpdSchedule,
            activeScheduleWeekDay, setActiveScheduleWeekDay,
            scheduleWeeksNew, setScheduleWeeksNew,
            refreshMissionDetails, setRefreshMissionDetails,
      
            refreshCompanyBigAccount, setRefreshCompanyBigAccount,

            reviewUntreatedDelete, setReviewUntreatedDelete,
            refreshUntreated, setRefreshUntreated,
      
            removeAllParameters,

            refreshQualifications, setRefreshQualifications,
            refreshQualification, setRefreshQualification,

            usersVivier, setUsersVivier,

            userVivierToArchive, setUserVivierToArchive,
            userVivierToArchiveUpdated, setUserVivierToArchiveUpdated,

            commentID, setCommentID,
            commentIDUpd, setCommentIDUpd,

            userData, setUserData,
            tempWorkerData, setTempWorkerData,

            tempWorkersWithIdentityFilesToComplete, setTempWorkersWithIdentityFilesToComplete,

            logViviers, setLogViviers,
            lastLogVivier, setLastLogVivier,

            totalPagesUsersVivier, setTotalPagesUsersVivier,
            countPerPageUsersVivier, setCountPerPageUsersVivier,
            countTotalUsersVivier, setCountTotalUsersVivier,
            globalUsersVivier, setGlobalUsersVivier,
            dataUsersVivier, setDataUsersVivier,
            resultUsersVivier, setResultUsersVivier,
            result2UsersVivier, setResult2UsersVivier,
            vivierUsersVivier, setVivierUsersVivier,
            isVivierLinkUsersVivier, setIsVivierLinkUsersVivier,

            resultUsersVivierAll, setResultUsersVivierAll,

            totalPagesVivierAllPage, setTotalPagesVivierAllPage,
            countPerPageVivierAllPage, setCountPerPageVivierAllPage,
            countTotalVivierAllPage, setCountTotalVivierAllPage,
            dataVivierAllPage, setDataVivierAllPage,
            resultVivierAllPage, setResultVivierAllPage,

            vivierAllHeaderVivier, setVivierAllHeaderVivier,
            nbAllViviersHeaderVivier, setNbAllViviersHeaderVivier,
            viviersRatingTempWorkerValidation, setViviersRatingTempWorkerValidation,
            vivierAllAddVivier, setVivierAllAddVivier,

            totalPagesVivierListUser, setTotalPagesVivierListUser,
            countTotalVivierListUser, setCountTotalVivierListUser,
            dataVivierListUser, setDataVivierListUser,
            resultVivierListUser, setResultVivierListUser,

            searchMissionResultMissionPeriod, setSearchMissionResultMissionPeriod,
            totalPagesMissionPeriod, setTotalPagesMissionPeriod,
            countTotalMissionPeriod, setCountTotalMissionPeriod,

            infoMissionDetails, setInfoMissionDetails,

            scheduleWeeksProvScheduleByIdMission, setScheduleWeeksProvScheduleByIdMission,

            searchTempWorkerInviteResultTempWorkersInviteMission, setSearchTempWorkerInviteResultTempWorkersInviteMission,

            searchTempWorkerAssignationResultTempWorkerPostulateMission, setSearchTempWorkerAssignationResultTempWorkerPostulateMission,
            totalPagesTempWorkerAssignationTempWorkerPostulateMission, setTotalPagesTempWorkerAssignationTempWorkerPostulateMission,
            countTotalTempWorkerAssignationTempWorkerPostulateMission, setCountTotalTempWorkerAssignationTempWorkerPostulateMission,
        }}>
            {children}
        </roadContext.Provider>
    )
}

export {ContextProvider, roadContext}