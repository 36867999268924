import Modalheader from "../../../components/header";
import ModalBody from "../../../components/body";
import ModalFooter from "../../../components/footer";
import Button from "../../../../button";
import UseContext from "../../../../../hooks/useContext";
import React, {useEffect, useState} from "react";
import ApiDatabase from "server";
import useModal from "../../../useModal";
import {NegativeThumb, PositiveThumb} from "../../../../../assets/icons";
import StarRating from "../../../../starRating/starRating";
import axios from "axios";
import Tooltip from "../../../../tooltips/Tooltips";

const RatingTempWorkerValidation = () => {
    const { objID, setObjID, setReviewUntreatedDelete, refreshUntreated, setRefreshUntreated, viviersRatingTempWorkerValidation, setViviersRatingTempWorkerValidation } = UseContext()
    const {toggle} = useModal()
    const token = localStorage.getItem('xsrfToken')
    const [review, setReview] = useState({})
    const [rating, setRating] = useState(null)
    const [isWaiting, setIsWaiting] = useState(false)
    const [step, setStep] = useState(1)
    const [viviersSelected, setViviersSelected] = useState([])
    const [reviewsMission, setReviewsMission] = useState([])
    const [isWaitingReviewMission, setIsWaitingReviewMission] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsWaiting(true)
        ApiDatabase.getRatingTempWorker({token, idTempWorkerRating: objID}, (data) => {
            setReview(data)
            setIsWaiting(false)
        }, (err) => {
            if (err.name !== "CanceledError") {
                console.log(err)
            }
        })
    }, [objID]);

    useEffect(() => {
        if(review?.user?._id && review?.mission?._id){
            ApiDatabase.getRatingTempWorkerByMission({token, idUser: review?.user?._id, idMission: review?.mission?._id}, (data) => {
                setRating(data.rating)
            }, (err) => {
                setRating(null)
            })
        }
    }, [review]);

    const hideModal = () => {
        toggle()
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setIsLoading(true)
        ApiDatabase.postTreatmentRatingTempWorker({token, idTempWorkerReview: objID}, (data) => {
            setIsLoading(false)
            setReviewUntreatedDelete(objID)
            setObjID(null)
            setRefreshUntreated(!refreshUntreated)
            toggle()
        }, (err) => {
            if (err.name !== "CanceledError") {
                console.log(err)
                setIsLoading(false)
            }
        })
    }

    const getViviers = () => {
        setViviersRatingTempWorkerValidation([])
        ApiDatabase.getVivierAll({},
            (data) => {
                setViviersRatingTempWorkerValidation(data)
            },
            (err) => {
                console.log(err)
            })
    }

    const getReviewsMission = async () => {
        setIsWaitingReviewMission(true)
        setReviewsMission([])
        ApiDatabase.getReviewsByTempworkerByMission({token, idUser: review?.user?._id, idMission: review?.mission?._id}, (data) => {
            setReviewsMission(data.reviews ?? [])
            setIsWaitingReviewMission(false)
        }, (err) => {
            if (err.name !== "CanceledError") {
                console.log(err)
                setReviewsMission([])
                setIsWaitingReviewMission(false)
            }
        })
    }

    useEffect(() => {
        if(step === 2){
            getViviers()
        }
        if(step === 3){
            getReviewsMission()
        }
    }, [step]);

    const handleSubmitVivier = (e) => {
        e.preventDefault()
        setIsLoading(true)
        if(viviersSelected.length > 0 && review?.user?._id){
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_URL}vivier/addVivier`,
                data: {
                    listUsers: [review?.user?._id],
                    listViviers: viviersSelected
                }
            })
                .then((res) => {
                    ApiDatabase.postTreatmentRatingTempWorker({token, idTempWorkerReview: objID}, (data) => {
                        setIsLoading(false)
                        setReviewUntreatedDelete(objID)
                        setObjID(null)
                        setRefreshUntreated(!refreshUntreated)
                        toggle()
                    }, (err) => {
                        if (err.name !== "CanceledError") {
                            console.log(err)
                            setIsLoading(false)
                        }
                    })
                })
                .catch((err) => {
                    if (err.name !== "CanceledError") {
                        console.log(err)
                        setIsLoading(false)
                    }
                })
        }
    }

    return (
        <>
            <Modalheader hide={hideModal} hideShow={true}>
                Observation de l'avis
            </Modalheader>
            <ModalBody>
                {step === 1 && (
                    <>
                        {isWaiting ? (
                            <>
                                <div className={'flex flex-col gap-6 animate-pulse'}>
                                    <div className={'text-gray-500'}>
                                        <div className="h-3 w-full bg-gray-200 rounded"></div>
                                        <div className="h-3 w-full bg-gray-200 rounded mt-1"></div>
                                    </div>
                                    <div className={'flex justify-between gap-3'}>
                                        <div className={'my-auto'}>
                                            <div className="h-3 w-20 bg-gray-200 rounded"></div>
                                            <div className={'text-gray-500'}>
                                                <div className="h-3 w-40 bg-gray-200 rounded mt-1"></div>
                                            </div>
                                        </div>
                                        <div className={'my-auto flex gap-2'}>
                                            <div className="h-3 w-20 bg-gray-200 rounded"></div>
                                            <div className="h-3 w-20 bg-gray-200 rounded"></div>
                                        </div>
                                    </div>
                                    <div className={'border rounded-lg p-4'}>
                                        <div className={'flex flex-col gap-6'}>
                                            <div className={'flex justify-between gap-3'}>
                                                <div className={''}>
                                                    <div className={''}>
                                                        <div className="h-3 w-40 bg-gray-200 rounded"></div>
                                                    </div>
                                                    <div className={'text-gray-500 mt-2'}>
                                                        <div className="h-3 w-80 bg-gray-200 rounded"></div>
                                                        <div className="h-3 w-80 bg-gray-200 rounded mt-1"></div>
                                                    </div>
                                                </div>
                                                <div className={''}>
                                                    <div className={'my-auto w-fit rounded-full p-2 bg-gray-100'}>
                                                        <div className="h-3 w-3 bg-gray-200 rounded"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'flex justify-between gap-3'}>
                                                <div className={'my-auto text-gray-500'}>
                                                    <div className="h-3 w-60 bg-gray-200 rounded"></div>
                                                </div>
                                                <div className={'my-auto'}>
                                                    <div className="h-3 w-40 bg-gray-200 rounded"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className={'flex flex-col gap-6'}>
                                    <div className={'text-gray-500'}>
                                        Consultez l'avis de {review?.user?.firstname} {review?.user?.lastname} pour la mission de {review?.mission?.title} n°{review?.mission?.id_mission} de {review?.name_commercial}.
                                    </div>
                                    <div className={'flex justify-between gap-3'}>
                                        <div className={'my-auto'}>
                                            <div>{review?.user?.firstname} {review?.user?.lastname}</div>
                                            <div className={'text-gray-500'}>
                                                Note en tant que <span className={'underline'}>{review?.mission?.title}</span>
                                            </div>
                                        </div>
                                        <div className={'my-auto flex gap-2'}>
                                            {rating !== null && (
                                                <>
                                                    <div className={'my-auto'}>
                                                        <Tooltip text={`${Math.round(rating * 100) / 100}/5 avis positif`} placement='top'>
                                                            <div><StarRating rating={rating}/></div>
                                                        </Tooltip>
                                                    </div>
                                                    <div className={'my-auto'}>
                                                        <Button size={'SM'} color={'SECONDARY'} onClick={() => setStep(3)}>Voir les avis</Button>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className={'border rounded-lg p-4'}>
                                        <div className={'flex flex-col gap-6'}>
                                            <div className={'flex justify-between gap-3'}>
                                                <div className={''}>
                                                    <div className={''}>
                                                        {review?.type === 'positive' && 'Avis positif'}
                                                        {(review?.type === 'negative' && review?.type_negative === 'performance') && 'Performance'}
                                                        {(review?.type === 'negative' && review?.type_negative === 'professionalism') && 'Professionalisme'}
                                                    </div>
                                                    <div className={'text-gray-500'}>
                                                        {review.comment}
                                                    </div>
                                                </div>
                                                <div className={''}>
                                                    {review?.type === 'positive' && (
                                                        <div className={'my-auto w-fit rounded-full p-2 bg-green-50'}>
                                                            <PositiveThumb wh={20} color={'#10B981'}/>
                                                        </div>
                                                    )}
                                                    {review?.type === 'negative' && (
                                                        <div className={'my-auto w-fit rounded-full p-2 bg-red-50'}>
                                                            <NegativeThumb wh={24} color={'#EF4444'}/>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className={'flex justify-between gap-3'}>
                                                <div className={'my-auto text-gray-500'}>
                                                    Par {review?.employee?.firstname} {review?.employee?.lastname}
                                                </div>
                                                <div className={'my-auto'}>
                                                    {review?.mission?.title} n°{review?.mission?.id_mission}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                )}
                {step === 2 && (
                    <>
                        {viviersRatingTempWorkerValidation?.length > 0 ? (
                            <div className={'flex flex-col gap-2'}>
                                {viviersRatingTempWorkerValidation.map((vivier, index) => (
                                    <label key={index} className="flex items-center space-x-2">
                                        <input type="checkbox"
                                               // search in vivier user_preferences array with id_user
                                               checked={vivier?.user_preferences?.some(pref => pref?.id_user?.toString() === review?.user?._id?.toString()) || viviersSelected?.includes(vivier._id)}
                                               onChange={() => {
                                                   if(viviersSelected?.includes(vivier._id)) setViviersSelected(viviersSelected.filter((vivierSelected) => vivierSelected !== vivier._id))
                                                   else setViviersSelected([...viviersSelected, vivier._id])
                                               }}
                                               disabled={vivier?.user_preferences?.some(pref => pref?.id_user?.toString() === review?.user?._id?.toString())}
                                        />
                                        <div className=' flex flex-col pl-4 '>
                                            <span>{vivier.name} </span>
                                            <span className=' ft-sm text-gray-400'>{vivier?.user_preferences?.length} candidats</span>
                                        </div>
                                    </label>
                                ))}
                            </div>
                        ) : (
                            'Aucun vivier disponible.'
                        )}
                    </>
                )}
                {step === 3 && (
                    <>
                        {isWaitingReviewMission ? (
                            <>
                                <div className={'flex flex-col gap-4 animate-pulse'}>
                                    {[1, 2, 3, 4, 5].map((i, index) => (
                                        <div key={index} className={'border rounded-lg p-4 animate-pulse'}>
                                            <div className={'flex flex-col gap-6'}>
                                                <div className={'flex justify-between gap-3'}>
                                                    <div className={''}>
                                                        <div className={''}>
                                                            <div className="h-3 w-40 bg-gray-200 rounded"></div>
                                                        </div>
                                                        <div className={'text-gray-500 mt-2'}>
                                                            <div className="h-3 w-80 bg-gray-200 rounded"></div>
                                                            <div className="h-3 w-80 bg-gray-200 rounded mt-1"></div>
                                                        </div>
                                                    </div>
                                                    <div className={''}>
                                                        <div className={'my-auto w-fit rounded-full p-2 bg-gray-100'}>
                                                            <div className="h-3 w-3 bg-gray-200 rounded"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'flex justify-between gap-3'}>
                                                    <div className={'my-auto text-gray-500'}>
                                                        <div className="h-3 w-60 bg-gray-200 rounded"></div>
                                                    </div>
                                                    <div className={'my-auto'}>
                                                        <div className="h-3 w-40 bg-gray-200 rounded"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        ) : (
                            <>
                                {reviewsMission?.length > 0 && (
                                    <div className={'flex flex-col gap-4'}>
                                        {reviewsMission?.map((reviewMission, index) => (
                                            <div key={index} className={'border rounded-lg p-4'}>
                                                <div className={'flex flex-col gap-6'}>
                                                    <div className={'flex justify-between gap-3'}>
                                                        <div className={''}>
                                                            <div className={''}>
                                                                {reviewMission?.type === 'positive' && 'Avis positif'}
                                                                {(reviewMission?.type === 'negative' && reviewMission?.type_negative === 'performance') && 'Performance'}
                                                                {(reviewMission?.type === 'negative' && reviewMission?.type_negative === 'professionalism') && 'Professionalisme'}
                                                            </div>
                                                            <div className={'text-gray-500'}>
                                                                {reviewMission.comment}
                                                            </div>
                                                        </div>
                                                        <div className={''}>
                                                            {reviewMission?.type === 'positive' && (
                                                                <div className={'my-auto w-fit rounded-full p-2 bg-green-50'}>
                                                                    <PositiveThumb wh={20} color={'#10B981'}/>
                                                                </div>
                                                            )}
                                                            {reviewMission?.type === 'negative' && (
                                                                <div className={'my-auto w-fit rounded-full p-2 bg-red-50'}>
                                                                    <NegativeThumb wh={24} color={'#EF4444'}/>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className={'flex justify-between gap-3'}>
                                                        <div className={'my-auto text-gray-500'}>
                                                            Par {reviewMission?.employee?.firstname} {reviewMission?.employee?.lastname}
                                                        </div>
                                                        <div className={'my-auto'}>
                                                            {reviewMission?.mission?.title} n°{reviewMission?.mission?.id_mission}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </>
                        )}
                    </>
                )}
            </ModalBody>
            <ModalFooter>
                {step === 1 && (
                    <>
                        {isLoading ? (
                            <>
                                <Button size={'LG'} color={'DISABLED'}>
                                    Confirmation ...
                                </Button>
                                <Button size={'LG'} color={'DISABLED'}>
                                    Ajouter dans un vivier
                                </Button>
                            </>

                        ) : (
                            <>
                                <Button size={'LG'} color={'SECONDARY'} onClick={handleSubmit}>
                                    Confirmer
                                </Button>
                                <Button size={'LG'} color={'PRIMARY'} onClick={() => setStep(step + 1)}>
                                    Ajouter dans un vivier
                                </Button>
                            </>
                        )}
                    </>
                )}
                {step === 2 && (
                    <>
                        {isLoading ? (
                            <>
                                <Button size={'LG'} color={'DISABLED'}>
                                    Retour
                                </Button>
                                <Button size={'LG'} color={'PRIMARY'}>
                                    Confirmation ...
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button size={'LG'} color={'SECONDARY'} onClick={() => setStep(step - 1)}>
                                    Retour
                                </Button>
                                <Button size={'LG'} color={'PRIMARY'} onClick={handleSubmitVivier}>
                                    Confirmer
                                </Button>
                            </>
                        )}
                    </>

                )}
                {step === 3 && (
                    <>
                        <Button size={'LG'} color={'SECONDARY'} onClick={() => setStep(1)}>
                            Retour
                        </Button>
                    </>

                )}
            </ModalFooter>
        </>
    )
}

export default RatingTempWorkerValidation;