import {useEffect, useState} from "react";
import ApiDatabase from "server";
import usefetchdata from "../../../../../hooks/usefetchdata";
import Divider from "../../../../divider/divider";
import Button from "../../../../button";
import {NoteIcon, TrashIcon} from "../../../../../assets/icons";
import {dateWordMonthShortHour} from "../../../../../helper/helper"
import UseContext from "../../../../../hooks/useContext";
import useModal from "../../../../modal/useModal";

const CommentTempWorker = ({idUser}) => {
    const token = localStorage.getItem('xsrfToken')
    const {userData} = usefetchdata()
    const {setCommentID, commentIDUpd} = UseContext()
    const {toggle} = useModal()
    const [comments, setComments] = useState([])
    const [isWaiting, setIsWaiting] = useState(true)
    const [commentText, setCommentText] = useState('')

    useEffect(() => {
        if (token && idUser && userData._id) {
            setIsWaiting(true)
            ApiDatabase.getCommentsByTempWorker({token, idUser}, (data) => {
                setComments(data)
                setIsWaiting(false)
            }, (err) => {
                console.log(err)
            })
        }
    }, [token, userData._id, idUser]);

    const addComment = () => {
        if (commentText.length >= 5) {
            ApiDatabase.postCommentTempWorker({token, idUser, comment: commentText}, (data) => {
                setComments(prevData => ([...prevData, data]))
                setCommentText('')
            }, (err) => {
                console.log(err)
            })
        }
    }

    const deleteComment = (idComment) => {
        if (idComment) {
            setCommentID(idComment)
            toggle('user_delete_comment')
        }
    }

    useEffect(() => {
        if (commentIDUpd) {
            const updatedComments = comments.filter(comment => comment._id !== commentIDUpd);
            setComments(updatedComments)
        }
    }, [commentIDUpd]);

    return (
        <div className={'flex flex-col h-full'}>
            <div className={'flex-1 overflow-y-auto my-auto'}>
                {isWaiting ? (
                    <div className={'animate-pulse flex flex-col gap-4'}>
                        {[1, 2, 3, 4, 5].map((i, index) => (
                            <>
                                <div key={index} className={'flex justify-between gap-2 border rounded-lg p-3'}>
                                    <div className={'flex flex-col gap-3'}>
                                        <div className={'flex gap-2 items-center font-black'}>
                                            <div><NoteIcon wh={24} color={'#374151'}/></div>
                                            <div><div className="w-80 h-2 bg-gray-200 rounded"></div></div>
                                        </div>
                                        <div className={'flex flex-col gap-3'}>
                                            <div className="w-full h-2 bg-gray-200 rounded"></div>
                                            <div className="w-full h-2 bg-gray-200 rounded"></div>
                                        </div>
                                        <div className={'text-gray-500'}>
                                            <div className="w-80 h-2 bg-gray-200 rounded"></div>
                                        </div>
                                    </div>
                                    <div className={'flex h-fit p-2 border rounded-lg hover:bg-gray-100'}>
                                        <div className={'cursor-pointer'}>
                                            <TrashIcon wh={20} color={'#111827'}/>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ))}
                    </div>
                ) : (
                    <div className={'flex flex-col gap-4'}>
                        {comments?.map((comment, index) => (
                            <>
                                <div key={index} className={'flex justify-between gap-2 border rounded-lg p-3'}>
                                    <div className={'flex flex-col gap-3'}>
                                        <div className={'flex gap-2 items-center font-black'}>
                                            <div><NoteIcon wh={24} color={'#374151'}/></div>
                                            <div>
                                                {comment.type === 'public' && 'Note publique'}
                                                {comment.type === 'private' && 'Note privée'}
                                            </div>
                                        </div>
                                        <div className={''}>
                                            {comment.comment}
                                        </div>
                                        <div className={'text-gray-500'}>
                                            {comment.employee.firstname} {comment.employee.lastname}, {dateWordMonthShortHour(comment.updatedAt)}
                                        </div>
                                    </div>
                                    <div className={'flex h-fit p-2 border rounded-lg hover:bg-gray-100'}>
                                        <div className={'cursor-pointer'} onClick={() => deleteComment(comment._id)}>
                                            <TrashIcon wh={20} color={'#111827'}/>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ))}
                    </div>
                )}
            </div>
            <div className={'flex flex-col flex-none gap-3'}>
                <Divider/>
                <label className='ft-sm text-gray-500'>
                    <div>
                        <textarea cols="30"
                                  rows="6"
                                  className="w-full pl-3 py-2 border border-gray-300 rounded-md"
                                  required
                                  minLength={5}
                                  wrap="true"
                                  value={commentText}
                                  onChange={e => setCommentText(e.target.value)}
                        />
                    </div>
                    <div className={'text-gray-500 text-sm'}>
                        5 caractères minimum
                    </div>
                </label>
                <div className={'flex justify-end'}>
                    <Button color={'PRIMARY'} size={'LG'} onClick={() => addComment()}>
                        Ajouter
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default CommentTempWorker