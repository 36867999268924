import useModal from "../../../useModal";
import Modalheader from "../../../components/header";
import ModalBody from "../../../components/body";
import ModalFooter from "../../../components/footer";
import Button from "../../../../button";
import UseContext from "../../../../../hooks/useContext";
import {useEffect, useState} from "react";
import ApiDatabase from "server";
import {toast} from "react-toastify";
import Banner from "../../../../banner/banner";

const ModalMissionDelete = () => {
    const {toggle} = useModal()
    const {objID, setModalDataReturn} = UseContext()
    const [isWaiting, setIsWaiting] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [missionData, setMissionData] = useState({})
    const [reasonMissionCancellation, setReasonMissionCancellation] = useState([])
    const [reasonSelected, setReasonSelected] = useState('')
    const token = localStorage.getItem('xsrfToken')

    useEffect(() => {
        if (token && objID) {
            setIsWaiting(true)
            ApiDatabase.getInfoMissionCancelDelete({token, idMission: objID, isAdmin: true}, (data) => {
                setMissionData(data.mission)
                setReasonMissionCancellation(data.reasonMissionDeletion)
                if ((data.mission.status === "delete" || data.mission.status === "cancel") && data.mission.reason) {
                    setReasonSelected(data.mission.reason)
                }
                setIsWaiting(false)
            }, (err) => {
                console.log('err', err)
                setIsWaiting(false)
            })
        }
    }, [token, objID]);

    const handleSubmit = () => {
        if (token && objID && reasonSelected) {
            setIsLoading(true)
            ApiDatabase.postDeleteMission({token: token, idMission: objID, reason: reasonSelected}, async (data) => {
                setModalDataReturn('mission deleted')
                toast.success('La mission a bien été annulée')
                setIsLoading(false)
                toggle()
            }, (err) => {
                toast.error('Une erreur est survenue lors de l\'annulation de la mission : ' + err)
                setIsLoading(false)
            })
        }
    }

    return (
        <>
            <Modalheader hide={toggle} hideShow={true}>
                Supprimer la mission
            </Modalheader>
            <ModalBody>
                {isWaiting ? (
                    <>
                        Chargement ...
                    </>
                ) : (
                    <>
                        {missionData.id_mission && (
                            <div className={'flex flex-col gap-3'}>
                                {missionData.contract_company[0]?.id_bestt && (
                                    <Banner type={'warning'}>
                                        <div className={'flex gap-1'}>Pensez à supprimer manuellement le contrat n°<div className={'font-black'}>{missionData.contract_company[0]?.id_bestt}</div>sur Bestt.</div>
                                    </Banner>
                                )}
                                <div className={''}>
                                    L’entreprise {missionData.name_commercial} sera notifiée pour la suppression de la mission n°{missionData.id_mission}.
                                </div>
                                {(missionData.status === "delete" || missionData.status === "cancel") && missionData.reason && (
                                    <div className={''}>Raison de l'annulation du client : {missionData.reason}</div>
                                )}
                                {missionData.status !== "delete" && missionData.status !== "cancel" && !missionData.reason && (
                                    <div>
                                        <label className='ft-sm text-gray-500'>
                                            <div>
                                                Motif
                                            </div>
                                            <div>
                                                <select
                                                    className='w-full h-10 pl-3 pr-8 ft-b border rounded-lg text-gray-900'
                                                    value={reasonSelected}
                                                    onChange={e => setReasonSelected(e.target.value)}
                                                >
                                                    <option value={''}>Sélectionnez une raison</option>
                                                    {
                                                        reasonMissionCancellation.map((mission, index) => {
                                                            return (
                                                                <option key={index}
                                                                        value={mission.reason}>{mission.reason}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </label>
                                    </div>
                                )}
                            </div>
                        )}
                    </>
                )}
            </ModalBody>
            <ModalFooter>
                <Button size={'LG'} color={'SECONDARY'} onClick={toggle}>Annuler</Button>
                {isLoading
                    ? <Button size={'LG'} color={'DISABLED'}>Confirmation ...</Button>
                    : <Button size={'LG'} color={'PRIMARY'} onClick={handleSubmit}>Confirmer</Button>
                }
            </ModalFooter>

        </>
    );
}

export default ModalMissionDelete
