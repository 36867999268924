import Modalheader from "../../../components/header";
import ModalBody from "../../../components/body";
import ModalFooter from "../../../components/footer";
import Button from "../../../../button";
import useModal from "../../../useModal";
import UseContext from "hooks/useContext";
import ApiDatabase from "../../../../../server";
import {toast} from "react-toastify";
import {useEffect} from "react";

const AdminModalMissionUnAssign = () => {
    const {userUnAssigned, setUserUnAssigned} = UseContext()
    const {toggle}=useModal()
    const token = localStorage.getItem('xsrfToken')

    const handleUnAssign = () => {
        setUserUnAssigned(false)
        toggle()
    }

    const unAssignMission = () => {
        if(userUnAssigned){
            if(userUnAssigned.idMission && userUnAssigned.userAssigned){
                ApiDatabase.unassignMission({token: token, idMission: userUnAssigned.idMission, userAssigned: userUnAssigned.userAssigned}, async (data) => {
                    toast.success('La mission a bien été désassignée')
                    setUserUnAssigned(false)
                    toggle()
                }, (err) => {
                    toast.error('Une erreur est survenue lors de la désassignation de la mission : ' + err.response.data.error)
                })
            } else {
                toast.error('Une erreur est survenue lors de la désassignation de la mission')
            }
        } else {
            toast.error('Une erreur est survenue lors de la désassignation de la mission')
        }
    }

    return (
        <>
            <Modalheader hide={handleUnAssign} hideShow={true}>
                Confirmer la modification
            </Modalheader>
            <ModalBody>
                <p>Êtes-vous sûr de vouloir dé-attribuer cette mission ?</p>
            </ModalBody>
            <ModalFooter >
                <Button size={'LG'} color={'SECONDARY'} onClick={handleUnAssign}>Annuler</Button>
                <Button size={'LG'} onClick={unAssignMission}>Confirmer</Button>
            </ModalFooter>
        </>
    )
}

export default AdminModalMissionUnAssign