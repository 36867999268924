import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {AuthProvider} from "context/authProvider";
import * as Sentry from "@sentry/react";

if(process.env.REACT_APP_IS_PROD === 'true') {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        authToken: process.env.REACT_APP_SENTRY_AUTH_TOKEN,
        integrations: [
            new Sentry.BrowserTracing(),
            new Sentry.Replay(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <AuthProvider>
            <Routes>
                <Route path='/*' element={<App/>}/>
            </Routes>
        </AuthProvider>
    </BrowserRouter>
);

