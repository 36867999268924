import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";

// import components
import Modalheader from "components/modal/components/header";
import ModalBody from "components/modal/components/body";
import ModalFooter from "components/modal/components/footer";
import axios from "axios";
import useModal from "components/modal/useModal";
import Button from "components/button";
import {ChevronLeft, ChevronRight, Loading2, SearchLoop} from 'assets/icons/index.js';
import UseContext from 'hooks/useContext.js';
import Pagination from 'components/pagination/pagination.js';
import useWindowDimensions from 'hooks/useWindowDimensions.js';
import Chip from 'components/chip/chip.js';
import {toast} from 'react-toastify';
import ApiDatabase from "server";

const ModalAddVivier = () => {
    const {toggle} = useModal()
    const {infoVivier, setUsersVivier, vivierAllAddVivier, setVivierAllAddVivier} = UseContext()
    const [search, setSearch] = useState("")
    const [isWaiting, setIsWaiting] = useState(true);
    const [listAddVivier, setAddVivier] = useState([])
    const [selectMQ, setSelectMQ] = useState(false)
    const [selectGP, setSelectGP] = useState(false)
    const [column, setColumn] = useState('test')
    const {height, width} = useWindowDimensions();

    const kanbanColumns = [
        { name: 'wish', title: 'Souhait', icon: '🧞' },
        { name: 'test', title: 'Test', icon: '⏳' },
        { name: 'valid', title: 'Validé', icon: '✅' },
        { name: 'refused', title: 'Refusé', icon: '❌' },
        { name: 'archive', title: 'Archivé', icon: '📦' }
    ];

    const handleCheckboxChange = (id) => {
        // Vérifiez si l'ID est déjà présent dans le tableau listAddVivier
        if (listAddVivier.includes(id)) {
            // Si l'ID est déjà présent, supprimez-le du tableau en filtrant les autres éléments
            setAddVivier(listAddVivier.filter((item) => item !== id));
        } else {
            // Si l'ID n'est pas présent, ajoutez-le au tableau en créant une nouvelle copie de listAddVivier
            setAddVivier([...listAddVivier, id]);
        }
    };

    const isAllUserVivier = (users, vivierUsers) => {
        return users.every((userID) => vivierUsers.some((vivierUser) => vivierUser.id_user === userID));
    }

    useEffect(() => {
        const fetchData = async () => {
            setIsWaiting(true)
            try {
                // Effectuez l'appel API en utilisant axios (ou tout autre moyen de requête HTTP)
                ApiDatabase.getVivierAll({},
                    (data) => {
                        let filteredResult = data;

                        if (search !== "") {
                            filteredResult = filteredResult.filter(
                                (item) => item.name.toLowerCase().search(search.toLowerCase().trim()) !== -1
                            );
                        }

                        setVivierAllAddVivier(filteredResult);
                        setIsWaiting(false)
                    },
                    (err) => {
                        console.log(err)
                        setIsWaiting(false)
                    })
            } catch (error) {
                if (error.name !== "CanceledError") {
                    // Gérez les erreurs lors de la récupération des données
                    console.log(error);
                }
            }
        };

        fetchData(); // Appelez la fonction pour récupérer les données depuis l'API
    }, [search, selectMQ, selectGP]);

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (listAddVivier.length > 0 && column !== '') {
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_URL}vivier/addVivier`,
                data: {
                    listUsers: infoVivier.listUsers,
                    listViviers: listAddVivier,
                    vivierStatus: column
                }
            })
                .then((res) => {
                    setUsersVivier(res.data)
                    toast.success(infoVivier.listUsers.length > 1 ? 'Les utilisateurs ont été ajoutés au vivier' : 'L\'utilisateur a été ajouté au vivier')
                    toggle()
                })
                .catch((err) => {
                    if (err.name !== "CanceledError") {
                        console.log(err)
                    }
                })
        } else {
            toast.error("Vous devez sélectionner au moins 1 candidat pour faire un ajout à un vivier")
        }
    }

    return (
        <>
            <Modalheader hide={toggle} hideShow={true}>
                Ajouter à un vivier
            </Modalheader>
            {/*Body*/}
            <ModalBody>
                <div className='flex flex-col w-full gap-8'>
                    <div className='flex items-center gap-x-4'>
                        <div className="relative text-gray-500 ">
                            <input
                                name="search"
                                value={search}
                                onChange={(e) => {
                                    setSearch(e.target.value)
                                }}
                                className=" w-56 h-10 pl-10 pr-3 ft-b placeholder-gray-500 border border-gray-300 rounded-md "
                                type="text"
                                placeholder='Nom du vivier'
                                required
                            />
                            <div className="absolute inset-y-0 left-0 flex items-center px-2 pointer-events-none">
                                <SearchLoop wh={18} color={'#666666'}/>
                            </div>
                        </div>
                    </div>
                    {isWaiting ?
                        (
                            <div
                                className={`flex flex-col ${width <= 460 ? 'gap-5' : 'gap-10'} justify-center items-center h-80`}>
                                <div className={'animate-spin'}>
                                    <Loading2 wh={(width <= 460 ? 40 : 60)} color={'#FF9600'}/>
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className='h-80 overflow-y-auto'>
                                    {vivierAllAddVivier.map((item, index) => {
                                        if (item._id.toString() === '64e8cfd10b4f73f442432c9f') return null
                                        else return (
                                            <div className='py-2' key={index}>
                                                <label className="flex items-center space-x-2">
                                                    <input type="checkbox"
                                                           checked={isAllUserVivier(infoVivier.listUsers, item?.user_preferences) ? true : item.checked}
                                                           onChange={() => handleCheckboxChange(item._id)}
                                                           disabled={isAllUserVivier(infoVivier.listUsers, item?.user_preferences)}
                                                    />
                                                    <div className=' flex flex-col pl-4 '>
                                                        <span>{item.name} </span>
                                                        <span
                                                            className=' ft-sm text-gray-400'>{item?.user_preferences.length} candidats</span>
                                                    </div>
                                                </label>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className={''}>
                                    <label className='ft-sm text-gray-500'>
                                        <div>
                                            Sélectionner la colonne
                                        </div>
                                        <div>
                                            <select className='w-full h-10 pl-3 pr-8 ft-b border rounded-lg text-gray-900'
                                                    value={column}
                                                    onChange={e => setColumn(e.target.value)}
                                            >
                                                {kanbanColumns?.map((kanbanColumn, index) => (
                                                    <option key={index} value={kanbanColumn.name}>
                                                        {kanbanColumn.icon} - {kanbanColumn.title}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </label>
                                </div>
                            </>
                        )
                    }
                </div>
            </ModalBody>
            <ModalFooter>
                <Button onClick={handleSubmit} size={'LG'}>Confirmer</Button>
            </ModalFooter>
        </>
    );
};


export default ModalAddVivier;