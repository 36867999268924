import {useLocation} from "react-router-dom";
import {ContextProvider} from "context/Context";
import Modal from 'components/modal/portal'
import SlideOver from "components/SlideOver/portal";
import Routes from "routes";
function App() {
  const location = useLocation();
  const tokenURL=location.search.split('=')[1]
  return (
      <ContextProvider>
        <Modal/>
        <SlideOver/>
        <Routes/>
      </ContextProvider>
  );
}

export default App;
