import React, {useEffect, useState} from 'react';
import Modalheader from "components/modal/components/header";
import UseModal from "components/modal/useModal";
import UseContext from "hooks/useContext";
import ModalBody from "components/modal/components/body";
import ModalFooter from "components/modal/components/footer";
import Button from "components/button";
import ApiDataBase from 'server'
import {Close} from "assets/icons";
import {toast} from "react-toastify";
import {useParams} from "react-router-dom";
import ApiDatabase from "../../../../../../server";


const Completeday = () => {
    const {toggle} = UseModal()
    const {id} = useParams()
    const {
        setTimeData,
        timeData,
        refreshTime,
        setRefreshTime
    } = UseContext()
    const [work, setWork] = useState(true)
    const [iTime, setITime] = useState(1)
    const [pTime, setPTime] = useState(true)
    const [error1, setError1] = useState('')
    const [error2, setError2] = useState('')
    const [error3, setError3] = useState('')

    const token = localStorage.getItem('xsrfToken')

    const handleITime = (e) => {
        setITime(iTime + 1)
    }
    const handleSITime = (e) => {
        setITime(iTime - 1)
        switch (iTime) {
            case 2 :
                setError2('')
                timeData.periode_two_start=''
                timeData.periode_two_end=''
                break
            case 3:
                setError3('')
                timeData.periode_three_start=''
                timeData.periode_three_end=''
                break
            default:
                break

        }
    }
    useEffect(() => {
            if (!work)
                setTimeData(prevData => ({...prevData, day_hours_ent: '0'}))
            else
                setTimeData(prevData => ({...prevData, day_hours_ent: ''}))
        }, [work]
    )
    // const getMinutes = s => s.split(":").reduce((acc, curr) => acc * 60 + +curr, 0);
    const getTimeDay = s => new Date(2023, 0o1, 0o1, s.split(":")[0], s.split(":")[1], 0o0)
    const cTime1 = (f) => ((f.periode_one_start ? getTimeDay(f.periode_one_start).getTime() : 0) >= (f.periode_one_end ? getTimeDay(f.periode_one_end).getTime() : 0) && f.periode_one_start !== '' && f.periode_one_end !== '')
    const cTime2 = (f) => ((f.periode_one_end ? getTimeDay(f.periode_one_end).getTime() : 0) >= (f.periode_two_start ? getTimeDay(f.periode_two_start).getTime() : 0) && f.periode_one_end !== '' && f.periode_two_start !== '')
    const cTime3 = (f) => ((f.periode_two_start ? getTimeDay(f.periode_two_start).getTime() : 0) >= (f.periode_two_end ? getTimeDay(f.periode_two_end).getTime() : 0) && f.periode_two_start !== '' && f.periode_two_end !== '')
    const cTime4 = (f) => ((f.periode_two_end ? getTimeDay(f.periode_two_end).getTime() : 0) >= (f.periode_three_start ? getTimeDay(f.periode_three_start).getTime() : 0) && f.periode_two_end !== '' && f.periode_three_start !== '')
    const cTime5 = (f) => ((f.periode_three_start ? getTimeDay(f.periode_three_start).getTime() : 0) >= (f.periode_three_end ? getTimeDay(f.periode_three_end).getTime() : 0) && f.periode_three_start !== '' && f.periode_three_end !== '')
    useEffect(() => {
        if (cTime1(timeData)) {
            setPTime(false)
            setError1('Veuillez indiquer une heure valide de fin pour la periode 1')
            // toast.error('Veuillez indiquer une heure valide de fin pour la periode 1')
        } else if (cTime2(timeData)) {
            setPTime(false)
            setError2('Veuillez indiquer une heure de début valide pour la periode 2')
            // toast.error('Veuillez indiquer une heure de début valide pour la periode 2')

        } else if (cTime3(timeData)) {
            setPTime(false)
            setError2('Veuillez indiquer une heure de fin valide pour la periode 2')
            // toast.error('Veuillez indiquer une heure de fin valide pour la periode 2')

        } else if (cTime4(timeData)) {
            setPTime(false)
            setError3('Veuillez indiquer une heure de début valide pour la periode 3')
            // toast.error('Veuillez indiquer une heure de début valide pour la periode 3')
        } else if (cTime5(timeData)) {
            setPTime(false)
            setError3('Veuillez indiquer une heure de fin valide pour la periode 3')
            // toast.error('Veuillez indiquer une heure de fin valide pour la periode 3')
        } else {
            setPTime(true)
            setError1('')
            setError2('')
            setError3('')
        }

    }, [timeData])

    const handleChange = e => {
        e.preventDefault()
        const type = e.type.target
        const name = e.target.name

        const value = type === 'checkbox'
            ? e.target.checked
            : e.target.value

        setTimeData(prevData => ({
            ...prevData,
            [name]: value
        }))
    }
    const handleClose = (e) => {
        toggle()
        setPTime(true)
        setTimeData({
            id: '',
            id_time_sheet: '',
            index: '',
            day_hours_ent: '',
            periode_one_start: '',
            periode_one_end: '',
            periode_two_start: '',
            periode_two_end: '',
            periode_three_start: '',
            periode_three_end: '',
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (timeData) {
            ApiDataBase.postUpdateDay({timeDay: timeData, token},
                (data) => {
                    toggle()
                    setRefreshTime(!refreshTime)
                    setTimeData({
                        id: '',
                        id_time_sheet: '',
                        index: '',
                        day_hours_ent: '',
                        periode_one_start: '',
                        periode_one_end: '',
                        periode_two_start: '',
                        periode_two_end: '',
                        periode_three_start: '',
                        periode_three_end: '',
                    })
                },
                (err) => {
                    if (err.name !== "CanceledError") {
                        console.log(err)
                    }
                })
        }
    }

    useEffect(() => {
        ApiDatabase.getTimeSheetDay({id: timeData.id},
            (data) => {
                if(data){
                    setTimeData({
                        id: data._id,
                        id_time_sheet: data.id_time_sheet,
                        index: '',
                        day_hours_ent: '',
                        periode_one_start: data.periode_one_company.start ?? '',
                        periode_one_end: data.periode_one_company.end ?? '',
                        periode_two_start: data.periode_two_company.start ?? '',
                        periode_two_end: data.periode_two_company.end ?? '',
                        periode_three_start: data.periode_three_company.start ?? '',
                        periode_three_end: data.periode_three_company.end ?? '',
                    })

                    if(data.day_hours_ent === '0h' || data.day_hours_ent === '0 h' || data.day_hours_ent === '0'){
                        setWork(false)
                    }

                    if(data.periode_two_company.start && data.periode_two_company.end){
                        setITime(2)
                    }
                    if(data.periode_three_company.start && data.periode_three_company.end){
                        setITime(3)
                    }
                }
            },
            (err) => {
                if (err.name !== "CanceledError") {
                    console.log(err)
                }
            }
        )
    }, [])

    return (
        <>

            <Modalheader hide={handleClose}
                         hideShow={true}>Relevé n° {timeData.id_time_sheet} </Modalheader>
            <ModalBody>
                {work ?
                    <>
                        <div className={'flex flex-col gap-6'}>
                            <p>Ajoutez les différentes périodes travaillées pour la journée.</p>
                            <div
                                className={`grid grid-cols-2 gap-4 flex items-center ${error1 !== '' ? 'border-2 border-red-600 rounded-md' : null} p-2`}>
                                <p className={'col-span-2'}>Période
                                    travaillée 1 </p>
                                {error1 !== '' ?
                                    <p className={' col-span-2 ft-b text-red-700'}>{error1}</p> : null}
                                <div>
                                    <label
                                        htmlFor="periode_one_start">Heure
                                        de début</label>
                                    <input type="time"
                                           onChange={handleChange}
                                           name={'periode_one_start'}
                                           value={timeData.periode_one_start}
                                           min={'00:00'}
                                           max={'23:59'}
                                           className="w-full h-10 pl-8 pr-3 ft-b placeholder-gray-600 border rounded-lg "
                                    />
                                </div>
                                <div>
                                    <label htmlFor="periode_one_end">Heure
                                        de Fin</label>
                                    <input type="time"
                                           name={'periode_one_end'}
                                           value={timeData.periode_one_end}
                                           onChange={handleChange}
                                           className="w-full h-10 pl-8 pr-3 ft-b placeholder-gray-600 border rounded-lg "
                                    />
                                </div>
                            </div>
                            {iTime > 1 ?
                                <div
                                    className={`grid grid-cols-2 gap-4 flex items-center ${error2 !== '' ? 'border-2 border-red-600 rounded-md' : null} p-2`}>
                                    <p>Période
                                        travaillée 2 </p>
                                    <span onClick={handleSITime}
                                          className={'flex justify-end'}><Close
                                        wh={24}
                                        color={'#6B7280'}/> </span>
                                    {error2 !== '' ?
                                        <p className={' col-span-2 ft-b text-red-700'}>{error2}</p> : null}
                                    <div>
                                        <label
                                            htmlFor="periode_two_start">Heure
                                            de début</label>
                                        <input type="time"
                                               onChange={handleChange}
                                               value={timeData.periode_two_start}
                                               name={'periode_two_start'}
                                               className="w-full h-10 pl-8 pr-3 ft-b placeholder-gray-600 border rounded-lg "
                                        />
                                    </div>
                                    <div>
                                        <label
                                            htmlFor="periode_two_end">Heure
                                            de Fin</label>
                                        <input type="time"
                                               onChange={handleChange}
                                               name={'periode_two_end'}
                                               value={timeData.periode_two_end}
                                               className="w-full h-10 pl-8 pr-3 ft-b placeholder-gray-600 border rounded-lg "
                                        />
                                    </div>
                                </div>
                                : null
                            }
                            {iTime > 2 ?
                                <div
                                    className={`grid grid-cols-2 gap-4 flex items-center ${error3 !== '' ? 'border-2 border-red-600 rounded-md' : null} p-2`}>
                                    <p>Période
                                        travaillée 3 </p>
                                    <span onClick={handleSITime}
                                          className={'flex justify-end'}><Close
                                        wh={24}
                                        color={'#6B7280'}/> </span>
                                    {error3 !== '' ?
                                        <p className={' col-span-2 ft-b text-red-700'}>{error3}</p> : null}
                                    <div>
                                        <label
                                            htmlFor="periode_three_start">Heure
                                            de début</label>
                                        <input type="time"
                                               onChange={handleChange}
                                               name={'periode_three_start'}
                                               value={timeData.periode_three_start}
                                               className="w-full h-10 pl-8 pr-3 ft-b placeholder-gray-600 border rounded-lg "
                                        />
                                    </div>
                                    <div>
                                        <label
                                            htmlFor="periode_three_end">Heure
                                            de Fin</label>
                                        <input type="time"
                                               onChange={handleChange}
                                               name={'periode_three_end'}
                                               value={timeData.periode_three_end}
                                               className="w-full h-10 pl-8 pr-3 ft-b placeholder-gray-600 border rounded-lg "
                                        />
                                    </div>
                                </div>
                                : null
                            }

                        </div>

                        {iTime < 3 ?
                            <div className={'mt-8'}>
                                <Button size={'LG'} color={'DEFAULT'}
                                        onClick={handleITime}>Ajouter
                                    une période</Button>
                            </div>
                            : null}
                    </>
                    : 'Vous avez indiqué ne pas avoir travaillé.'

                }

            </ModalBody>
            <ModalFooter>
                <div onClick={(e) => {
                    setWork(!work)
                }}
                     className={'absolute left-0 ml-8 cursor-pointer text-yellow-500 underline'}>
                    {work ? 'Jour non travaillé ?' : 'Jour travaillé ?'}
                </div>
                <div className={'flex mt-32 tab:mt-0 tab:gap-8'}>
                    <Button size={'LG'} color={'SECONDARY'}
                            onClick={handleClose}>Annuler</Button>
                    <Button size={'LG'}
                            onClick={handleSubmit}>Confirmer</Button>
                </div>

            </ModalFooter>
        </>
    );
};

export default Completeday;