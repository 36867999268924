import React, {useEffect, useState} from 'react';

//import Component
import PageForm from "components/pageForm/PageForm";
import AdminCollapse from "../../components/collapse/AdminCollapse";
import ApiDatabase from "server";
import useContext from "../../hooks/useContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import UseContext from "../../hooks/useContext";

const IdentityValidation = () => {
    const {updateTempWorker, setUpdateTempWorker, tempWorkersWithIdentityFilesToComplete, setTempWorkersWithIdentityFilesToComplete} = UseContext()
    const [tempWorker, setTempWorker] = useState([]);
    const token = localStorage.getItem('xsrfToken')
    const {height, width} = useWindowDimensions();
    const [isWaiting, setIsWaiting] = useState(false);

    const getTempWorkers = () => {
        setIsWaiting(true);
        ApiDatabase.getAllUsersWithIdentityFilesToComplete({token:token},
            (data) => {
                setTempWorkersWithIdentityFilesToComplete(data);
                setIsWaiting(false)
            },
            (err) => {
                if (err.name !== "CanceledError") {
                    console.error(err)
                    setIsWaiting(false)
                }
            })
    }

    useEffect(() => {
        if(updateTempWorker){
            ApiDatabase.getUserWithIdentityFilesToComplete({token: token, idTempWorker: updateTempWorker},
                (data) => {
                    setTempWorker(data);
                    setUpdateTempWorker(false);
                },
                (err) => {
                    if (err.name !== "CanceledError") {
                        console.error(err)
                    }
                })
        }
    }, [updateTempWorker])

    useEffect(() => {
        getTempWorkers();
    }, []);
    
    return (
        <>
            <PageForm>
                <div className={'col-span-12 md:m-8'}>
                    {
                        isWaiting ? (
                            <>
                                <div className={'mb-4 text-lg font-bold'}>
                                    <div className={'h-3 bg-gray-200 rounded w-20'}></div>
                                </div>
                                <div className={`${width > 768 ? 'flex flex-col gap-4' : 'flex'}`}>
                                    {
                                        [1, 2, 3, 4, 5].map((i, index) => (
                                            <AdminCollapse key={index} tempWorker={i} tempWorkerToEdit={i} isWaiting={isWaiting}/>
                                        ))
                                    }
                                </div>
                            </>
                        ) : (
                            <>
                                {
                                    tempWorkersWithIdentityFilesToComplete.length > 0 ? (
                                        <>
                                            <div className={'mb-4 text-lg font-bold'}>Total : {tempWorkersWithIdentityFilesToComplete.length}</div>
                                            <div className={`${width > 768 ? 'flex flex-col gap-4' : 'flex'}`}>
                                                {
                                                    tempWorkersWithIdentityFilesToComplete.map((tempWorkerInfos, index) => (
                                                        <AdminCollapse key={tempWorkerInfos._id} tempWorker={tempWorkerInfos} tempWorkerToEdit={tempWorker}/>
                                                    ))
                                                }
                                            </div>
                                        </>
                                    ) : (
                                        'Aucun utilisateur'
                                    )
                                }
                            </>
                        )
                    }
                </div>
            </PageForm>
        </>
    );
}

export default IdentityValidation;